import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, concatMap, from, takeWhile } from 'rxjs';
import { CsToolApiResponseModel } from 'src/app/core/domains/models/cs-tool-api-response.model';
import { environment } from 'src/environments/environment';
import { WabaPhoneDetails } from '../../domains/models/waba-phone-details.module';
import { OboToSharedModel } from '../../domains/models/obo-to-shared.model';
import { EndpointDetailsModel } from '../../domains/models/endpoint-details.model';
import { Activities } from '../../domains/models/activities.module';
import { Incident, Incidents } from '../../domains/models/incident.model';
import { WaNotificationGroup } from '../../domains/models/wa-notification-group.model';
import { KnownIssueNotifications } from '../../domains/models/known-issue-notifications.module';
import { InstatusComponents } from '../../domains/models/instatus-components.model';
import { InstatusIncidentDetails } from '../../domains/models/instatus-incident-details.model';
import { IntercomReviews } from '../../domains/models/intercom-review.model';

export interface StartMigrationBody {
  oldWabaId: string;
  newWabaId: string;
  phoneNnumber: string;
  cc: string;
}

@Injectable({
  providedIn: 'root',
})
export class CsToolApiService {
  constructor(private httpClient: HttpClient) {}

  startMigration(body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/migration/start`,
      body
    );
  }

  markAsComplete(migrationId: string): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/migration/markAsComplete/${migrationId}`,
      {}
    );
  }

  handleSecondStep(
    migrationId: string,
    userPassword: string,
    appId: string
  ): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/migration/handleSecondStep/${migrationId}/${userPassword}/${appId}`,
      {}
    );
  }

  handleSecondStepCloud(migrationId: string): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/migration/handleSecondStepCloud/${migrationId}`,
      {}
    );
  }

  handleThirdStep(migrationId: string, code: string): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/migration/handleThirdStep/${migrationId}/${code}`,
      {}
    );
  }

  handleFourthStep(endpoints: EndpointDetailsModel[]): Observable<any> {
    return from(endpoints).pipe(
      concatMap((endpoint) => this.callEndpoint(endpoint)),
      takeWhile(
        (response) => response === null || response.success !== false,
        true
      )
    );
  }

  private callEndpoint(endpoint: EndpointDetailsModel): Observable<any> {
    let options = {};

    if (endpoint.method === 'GET') {
      options = {
        params: new HttpParams({ fromObject: endpoint.params }),
      };
    } else if (endpoint.method === 'POST') {
      options = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: endpoint.body,
      };
    }

    return this.httpClient.request(endpoint.method, endpoint.url, options);
  }

  getMigration(oldWabaId, phoneNumber): Observable<OboToSharedModel> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<OboToSharedModel>(
      `${environment.csToolApi}/migration/${oldWabaId}/${phoneNumber}`,
      { params }
    );
  }

  setPaymentMethod(externalId, body) {
    return this.httpClient.post<CsToolApiResponseModel<any>>(
      `${environment.csToolApi}/graph/waba/${externalId}/setinfo`,
      body
    );
  }

  getSubscribedApps(externalId): Observable<any> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${environment.csToolApi}/graph/waba/${externalId}/getSubscribedApp`,
      { params }
    );
  }

  getWabaInfo(externalId): Observable<any> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${environment.csToolApi}/graph/waba/${externalId}/info`,
      { params }
    );
  }

  getWabaPhoneNumbers(externalId): Observable<any> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${environment.csToolApi}/graph/waba/${externalId}/phone_numbers`,
      { params }
    );
  }

  getWabaUsers(externalId): Observable<any> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${environment.csToolApi}/graph/waba/${externalId}/getWabaUsers`,
      { params }
    );
  }

  assignWabaManager(externalId): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.csToolApi}/graph/waba/${externalId}/assignWabaManager`,
      {}
    );
  }

  assignWabaDev(externalId): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.csToolApi}/graph/waba/${externalId}/assignWabaDev`,
      {}
    );
  }

  addSubscribeApp(externalId): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.csToolApi}/graph/waba/${externalId}/addSubscribeApp`,
      {}
    );
  }

  // CAPI

  reRegisterCloudApiNumber(phoneNumberId, body): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.csToolApi}/graph/waba/${phoneNumberId}/reRegisterCloudApiNumber`,
      body
    );
  }

  registerCloudApiDisplayName(body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/cloudApi/registerCloudApiDisplayName`,
      body
    );
  }

  triggerCloudOtp(body: {}): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.csToolApi}/cloudApi/triggerOtp`,
      body
    );
  }

  verifyCloudOtp(body: {}): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.csToolApi}/cloudApi/verifyOtp`,
      body
    );
  }

  getCapiPhoneInfo(externalId: string): Observable<WabaPhoneDetails> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<WabaPhoneDetails>(
      `${environment.csToolApi}/cloudApi/getCapiPhoneInfo/${externalId}`,
      { params }
    );
  }
  // graph
  getExternalInfo(externalId: string): Observable<WabaPhoneDetails> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<WabaPhoneDetails>(
      `${environment.csToolApi}/graph/waba/${externalId}`,
      { params }
    );
  }

  // Graph API

  migratePhoneNumber(body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/graphApi/migratePhoneNumber`,
      body
    );
  }

  triggerOnPremOtp(body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/graphApi/triggerOnPremOtp`,
      body
    );
  }

  verifyOnPremOtp(body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/graphApi/verifyOnPremOtp`,
      body
    );
  }

  migrateTemplates(body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApi}/graphApi/migrateTemplates`,
      body
    );
  }

  getCapiThroughput(phoneNumberId): Observable<any> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${environment.csToolApi}/graph/waba/${phoneNumberId}/getCapiThroughput`,
      { params }
    );
  }

  // HELPER CONTROLLER
  restartSubscription(index: number, body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApiHelperController}/restartSubscription/${index}`,
      body
    );
  }

  getAllocationId(businessId: string): Observable<any> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get(
      `${environment.csToolApiHelperController}/get-allocation-id/${businessId}`,
      { params }
    );
  }

  verifyCreditLineDeteled(allocationId: string): Observable<any> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get(
      `${environment.csToolApiHelperController}/verify-credit-line-deleted/${allocationId}`,
      { params }
    );
  }

  deleteCreditLine(allocationId: string): Observable<any> {
    return this.httpClient.delete(
      `${environment.csToolApiHelperController}/delete-credit-line/${allocationId}`
    );
  }

  // INCIDENT CONTROLLER
  createIncident(body: {}) {
    return this.httpClient.post<CsToolApiResponseModel<any>>(
      `${environment.csToolApiIncidentController}`,
      body
    );
  }

  getIncidents(fromObject: {}): Observable<Incidents> {
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<Incidents>(
      `${environment.csToolApiIncidentController}`,
      { params }
    );
  }

  getIncident(incidentId: string): Observable<Incident> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<Incident>(
      `${environment.csToolApiIncidentController}/${incidentId}`,
      { params }
    );
  }

  updateIncident(incidentId: string, body: {}) {
    return this.httpClient.post<CsToolApiResponseModel<any>>(
      `${environment.csToolApiIncidentController}/${incidentId}`,
      body
    );
  }

  uploadRca(incidentId: string, file: FormData) {
    return this.httpClient.post<CsToolApiResponseModel<any>>(
      `${environment.csToolApiIncidentController}/${incidentId}/uploadFile`,
      file
    );
  }

  downloadRca(incidentId: string) {
    return this.httpClient.get(
      `${environment.csToolApiIncidentController}/${incidentId}/downloadFile`,
      { responseType: 'blob' }
    );
  }

  deleteRca(incidentId: string) {
    return this.httpClient.delete<CsToolApiResponseModel<any>>(
      `${environment.csToolApiIncidentController}/${incidentId}/deleteFile`
    );
  }

  deleteIncident(incidentId: string) {
    return this.httpClient.delete<CsToolApiResponseModel<any>>(
      `${environment.csToolApiIncidentController}/${incidentId}`
    );
  }

  getKnownIssueNotificaitons(
    incidentId: string
  ): Observable<KnownIssueNotifications[]> {
    return this.httpClient.get<KnownIssueNotifications[]>(
      `${environment.csToolApiIncidentController}/notifications/${incidentId}`
    );
  }

  // FIRM TEAM CONTROLLER
  getWaNotificationGroups(): Observable<WaNotificationGroup[]> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<WaNotificationGroup[]>(
      `${environment.csToolApiWaNotificationGroupController}`,
      { params }
    );
  }

  updateWaGroup(groupId: string, body: {}) {
    return this.httpClient.post<CsToolApiResponseModel<any>>(
      `${environment.csToolApiWaNotificationGroupController}/${groupId}`,
      body
    );
  }

  createWaGroup(body: {}) {
    return this.httpClient.post<CsToolApiResponseModel<any>>(
      `${environment.csToolApiWaNotificationGroupController}`,
      body
    );
  }

  deleteWaGroup(groupId: string) {
    return this.httpClient.delete<CsToolApiResponseModel<any>>(
      `${environment.csToolApiWaNotificationGroupController}/${groupId}`
    );
  }

  // INSTATUS CONTOLLER
  getInstatusComponents(): Observable<InstatusComponents[]> {
    return this.httpClient.get<InstatusComponents[]>(
      `${environment.csToolApiInstatusController}/components`
    );
  }

  getInstatusTemplates(): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.csToolApiInstatusController}/templates`
    );
  }

  getInstatusIncident(incidentId: string): Observable<InstatusIncidentDetails> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<InstatusIncidentDetails>(
      `${environment.csToolApiInstatusController}/${incidentId}`,
      { params }
    );
  }

  updateInstatusIncident(incidentId: string, body: {}): Observable<any> {
    return this.httpClient.put(
      `${environment.csToolApiInstatusController}/${incidentId}`,
      body
    );
  }

  deleteInstatusIncident(
    instatusIncidentId: string,
    knownIssueIncidentId: string
  ): Observable<any> {
    return this.httpClient.delete(
      `${environment.csToolApiInstatusController}/${instatusIncidentId}/${knownIssueIncidentId}`
    );
  }

  deleteInstatusIncidentUpdate(
    incidentId: string,
    updateId: string
  ): Observable<any> {
    return this.httpClient.delete(
      `${environment.csToolApiInstatusController}/update/${incidentId}/${updateId}`
    );
  }

  createInstatusIncident(
    knownIssueIncidentId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApiInstatusController}/${knownIssueIncidentId}`,
      { body }
    );
  }

  addInstatusUpdate(incidentId: string, body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApiInstatusController}/${incidentId}/incident-updates`,
      body
    );
  }

  editInstatusUpdate(
    incidentId: string,
    incidentUpdateId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.put(
      `${environment.csToolApiInstatusController}/${incidentId}/incident-updates/${incidentUpdateId}`,
      body
    );
  }

  // Intercom Review
  // TODO: CST-505

  getReviewData(): Observable<IntercomReviews> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<IntercomReviews>(
      `${environment.csToolApiReviewController}`,
      { params }
    );
  }

  downloadFile() {
    return this.httpClient.get(
      `${environment.csToolApiReviewController}/export`,
      { responseType: 'blob' }
    );
  }
}
