import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { SSLCheck } from '../domains/models/webhook';

@Injectable({
  providedIn: 'root',
})
export class WebhookService {
  webhookRoute: string = `${environment.tppCsToolAPI.url}/v1/webhook`;

  constructor(private httpClient: HttpClient) {}

  sslCheck(body: {}): Observable<SSLCheck> {
    return this.httpClient.post<SSLCheck>(
      `${this.webhookRoute}/ssl-check`,
      body
    );
  }

  testCallback(body: {}): Observable<any> {
    return this.httpClient.post<any>(
      `${this.webhookRoute}/test-callback`,
      body
    );
  }
}
