import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface StartMigrationBody {
  oldWabaId: string;
  newWabaId: string;
  phoneNnumber: string;
  cc: string;
}

@Injectable({
  providedIn: 'root',
})
export class CsToolApiService {
  constructor(private httpClient: HttpClient) {}

  // HELPER CONTROLLER
  restartSubscription(index: number, body: {}): Observable<any> {
    return this.httpClient.post(
      `${environment.csToolApiHelperController}/restartSubscription/${index}`,
      body
    );
  }
}
