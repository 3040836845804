import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { HubService } from 'src/app/core/services/hub.service';
import timezonJson from 'src/assets/data/meta-timezone-id.json';

@Component({
  selector: 'app-facebook-id-validator',
  templateUrl: './facebook-id-validator.component.html',
  styleUrls: ['./facebook-id-validator.component.scss'],
})
export class FacebookIdValidatorComponent implements OnInit {
  // Partner id of 360 Dialog
  @Input() partnerId: string = 'SvAiK8PA';
  facebookId: string;
  preview;
  constructor(
    protected activeModal: NgbActiveModal,
    private hubService: HubService,
    private toastrService: ToastrService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {}

  validateFacebookId() {
    if (!this.facebookId) {
      this.toastrService.error('Please insert Meta business ID');
      return;
    }
    this.hubService
      .getBusinessAccount(this.partnerId, this.facebookId)
      .subscribe({
        next: (result) => {
          // this.spinnerService.hide(this);
          this.toastrService.success('Meta business ID is valid!');
          this.preview = result;
        },
        error: (err) => {
          // this.spinnerService.hide(this);
          this.toastrService.error(
            `Meta business ID, Error: ${this.helperService.parseHubErrorMessage(
              err
            )}`
          );
        },
      });
  }

  close() {
    this.activeModal.dismiss();
  }

  getTimezone(timezoneId) {
    return timezonJson[timezoneId][timezoneId];
  }
  clear() {
    this.facebookId = null;
    this.preview = null;
  }
}
