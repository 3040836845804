import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  currentYear: number;
  oldLogin: boolean = false;

  constructor(public authService: AuthenticationService) {}

  ngOnInit(): void {
    this.redirectIfLoggedIn();
    this.currentYear = new Date().getFullYear();
  }

  redirectIfLoggedIn(): void {
    this.authService.isLoggedIn$.pipe(take(1)).subscribe({
      next: (res) => {
        if (res === true) {
          this.authService.redirectAfterLogin();
        }
      },
    });
  }

  login(): void {
    this.authService.login();
  }
}
