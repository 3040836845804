import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  NgbActiveModal,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';
import {
  Observable,
  Subject,
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs';
import { AuthUserModel } from 'src/app/core/domains/models/auth-user.model';
import { CatalogAssignedUsers } from 'src/app/core/domains/models/catalog-assigned-users.module';
import {
  CatalogUserManagementAuditCreateModel,
  CatalogUserManagementAuditUpdateModel,
  GetCatalogUserManagementAuditOptions,
} from 'src/app/core/domains/models/catalog-user-management-audit.model';
import {
  BusinessUser,
  BusinessUserListModel,
} from 'src/app/core/domains/models/waba-users.model';
import { CatalogUserManagementAuditService } from 'src/app/core/services/catalog-user-management-audit.service';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { SharedDataService } from 'src/app/core/services/shared-data/shared-data.service';
import { TppMetaService } from 'src/app/core/services/tpp-meta.service';

const DEBOUNCE_TIME = 300;

@Component({
  selector: 'app-catalog-management',
  templateUrl: './catalog-management.component.html',
})
export class CatalogManagementComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  catalogError: boolean = false;
  catalogErrorMessage: string = '';
  fetchUserError: boolean = false;
  assignError: boolean = false;
  fetchUserErrorMessage: string =
    'Fail to load business manager users. Please reach out in #cs-tool channel or create a ticket';
  assignErrorMessage: string =
    'Fail to update the catalog. Please reach out in #cs-tool channel or create a ticket';
  catalogId: string = '';
  catalogAssignedUsers: CatalogAssignedUsers = null;
  businessUsers: BusinessUserListModel = {};
  form: FormGroup;
  availableTasks: string[] = ['MANAGE', 'ADVERTISE'];
  user: AuthUserModel = {};
  selectedTasks: string[] = [];

  constructor(
    protected activeModal: NgbActiveModal,
    private helperService: HelperService,
    private tppMetaService: TppMetaService,
    private formBuilder: FormBuilder,
    private catalogUserManagementAuditService: CatalogUserManagementAuditService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit(): void {
    this.getBusinessUsers();
    this.generateForm();
    this.getUser();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setFormValue(event: NgbTypeaheadSelectItemEvent<BusinessUser>): void {
    this.form.patchValue({ user: event.item });
  }

  close() {
    this.activeModal.dismiss();
  }

  search = (text$: Observable<string>): Observable<BusinessUser[]> =>
    text$.pipe(
      debounceTime(DEBOUNCE_TIME),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.businessUsers?.data.filter((user) =>
              user.name.toLowerCase().includes(term.toLowerCase())
            )
      )
    );

  validateSelection(): void {
    const selectedValue = this.form.get('user')?.value;
    const validUser = this.businessUsers?.data?.find(
      (user) => user === selectedValue
    );
    if (!validUser) {
      this.form.get('user')?.setErrors({ invalidSelection: true });
    } else {
      this.form.get('user')?.setErrors(null);
    }
  }

  resultFormatter = (user: BusinessUser): string => user.name;
  inputFormatter = (user: BusinessUser): string => user.name;

  assignToMe(): void {
    const hubUsername = localStorage.getItem('hub_username');
    const user = this.businessUsers.data.find(
      (user) => user.name === hubUsername
    );
    if (!user) {
      this.helperService.showErrorToastr(
        'Fail to find user in the business manager. Please select the user manually.'
      );
      return;
    }
    this.form.patchValue({
      user: user,
    });
    this.validateSelection();
  }

  onTaskChange(task: string, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;

    if (task === 'MANAGE') {
      if (isChecked) {
        this.form.get('task_ADVERTISE')?.disable();
        this.form.get('task_ADVERTISE')?.setValue(false);
      } else {
        this.form.get('task_ADVERTISE')?.enable();
      }
    }
    const selectedTasks = this.availableTasks.filter(
      (task) => this.form.get(`task_${task}`)?.value
    );
    this.form.get('tasks')?.setValue(selectedTasks);
    if (selectedTasks.length > 0) {
      this.form.get('tasks')?.setErrors(null);
    } else {
      this.form.get('tasks')?.setErrors({ required: true });
    }
  }

  getCatalog(): void {
    this.catalogError = false;
    this.helperService.showSpinner();
    this.tppMetaService.getCatalogAssignedUsers(this.catalogId).subscribe({
      next: (res) => {
        this.catalogAssignedUsers = res;
        this.helperService.hideSpinner(false);
      },
      error: (err) => {
        this.catalogError = true;
        if (
          err?.error?.error?.message.includes(
            `Unsupported get request. Object with ID '${this.catalogId}' does not exist, cannot be loaded due to missing permissions`
          )
        ) {
          this.catalogErrorMessage =
            'Catalog does not exist or 360dialog does not have access to it.';
        } else {
          this.catalogErrorMessage =
            'Fail to check if catalog exists. Please reach out in #cs-tool channel or create a ticket';
        }
        this.helperService.hideSpinner(false);
      },
    });
  }

  addUserToCatalog() {
    this.assignError = false;
    this.helperService.showSpinner();
    if (!this.form.valid) {
      this.helperService.hideSpinner(false);
      this.helperService.showErrorToastr('Invalid input data.');
      return;
    }
    const formValues = this.form.value;
    const userId = formValues.user.id;
    const selectedTasks = this.availableTasks.filter(
      (task) => this.form.get(`task_${task}`)?.value
    );
    this.selectedTasks = selectedTasks;
    const tasks =
      '[' + selectedTasks.map((task) => `"${task}"`).join(',') + ']';
    this.tppMetaService
      .assignUserToCatalog(this.catalogId, userId, tasks)
      .subscribe({
        error: (err) => {
          this.assignError = true;
          this.helperService.hideSpinner(false);
        },
        complete: () => {
          this.manageAudit(userId);
          this.helperService.showSuccessToastr('User added successfully!');
          this.getCatalog();
        },
      });
  }

  deleteUserFromCatalog(userId: string) {
    this.assignError = false;
    this.helperService.showSpinner();
    this.tppMetaService
      .deleteUserFromCatalog(this.catalogId, userId)
      .subscribe({
        error: (err) => {
          this.assignError = true;
          this.helperService.hideSpinner(false);
        },
        complete: () => {
          this.manageAudit(userId, true);
          this.helperService.showSuccessToastr('User deleted successfully!');
          this.getCatalog();
        },
      });
  }

  manageAudit(user_fb_id: string, deleteUser: boolean = false): void {
    const getWabaUserManagementAuditOptions: GetCatalogUserManagementAuditOptions =
      {
        reset: true,
        user_fb_id: user_fb_id,
        catalog_id: this.catalogId,
        valid: true,
      };
    this.catalogUserManagementAuditService
      .getCatalogManagementAudit(getWabaUserManagementAuditOptions)
      .subscribe({
        next: (res) => {
          if (res.total_count == 0 && !deleteUser) {
            this.createCatalogManagementAudit(user_fb_id);
          } else if (res.total_count != 0) {
            this.updateCatalogManagementAudit(
              res.data[0].id,
              user_fb_id,
              deleteUser
            );
          }
        },
        error: (err) => {
          throw new Error(`Fail to fetch audit: ${err}`);
        },
      });
  }

  private updateCatalogManagementAudit(
    auditId: string,
    user_fb_id: string,
    deleteUser
  ): void {
    const catalogUserManagementAuditUpdateModel: CatalogUserManagementAuditUpdateModel =
      {
        valid: false,
        modified_by: this.user.user_id,
      };
    this.catalogUserManagementAuditService
      .updateCatalogManagementAudit(
        auditId,
        catalogUserManagementAuditUpdateModel
      )
      .subscribe({
        error: (err) => {
          throw new Error(`Fail to update audit: ${err}`);
        },
        complete: () => {
          if (!deleteUser) {
            this.createCatalogManagementAudit(user_fb_id);
          }
        },
      });
  }

  private createCatalogManagementAudit(user_fb_id: string): void {
    const catalogManagementAuditCreate: CatalogUserManagementAuditCreateModel =
      {
        user_id: this.user.user_id,
        user_name: localStorage.getItem('hub_username'),
        user_fb_id: user_fb_id,
        catalog_id: this.catalogId,
        tasks: this.selectedTasks,
      };
    this.catalogUserManagementAuditService
      .createCatalogManagementAudit(catalogManagementAuditCreate)
      .subscribe({
        error: (err) => {
          throw new Error(`Fail to store audit: ${err}`);
        },
      });
  }

  private getBusinessUsers(): void {
    this.tppMetaService.getBusinessUsers().subscribe({
      next: (res) => (this.businessUsers = res),
      error: (err) => (this.fetchUserError = true),
    });
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({
      user: ['', Validators.required],
      tasks: ['', [Validators.required]],
    });
    this.availableTasks.forEach((task) => {
      this.form.addControl(`task_${task}`, this.formBuilder.control(false));
    });
  }

  private getUser(): void {
    this.sharedDataService.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: AuthUserModel) => {
        this.user = user;
      });
  }
}
