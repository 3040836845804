<form [formGroup]="form">
  <div class="mb-3">
    <label for="businessManagerId" class="form-label"
      >BUSINESS MANAGER ID</label
    >
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        id="businessManagerId"
        formControlName="businessManagerId"
        [class.is-invalid]="
          form.get('businessManagerId').invalid &&
          form.get('businessManagerId').touched
        "
      />
      <button
        class="btn btn-primary"
        type="button"
        [disabled]="form.get('businessManagerId').invalid"
        (click)="getAllocationId()"
      >
        Check
      </button>
    </div>
    <div
      class="invalid-feedback"
      *ngIf="form.get('businessManagerId')['errors']?.['required'] && form.get('businessManagerId').touched"
    >
      Business Manager ID is required
    </div>
  </div>
  <div
    class="alert alert-success d-flex align-items-center justify-content-between"
    *ngIf="state.allocationId && !state.error"
  >
    <span>There is a credit line attached to this business manager.</span>
    <button class="btn btn-danger btn-nowrap" (click)="revokeCreditLine()">
      Revoke credit line
    </button>
  </div>

  <div class="alert alert-danger" *ngIf="state.error">
    <div
      class="d-flex flex-column flex-md-row gap-2 align-items-md-center justify-content-between"
    >
      <span class="text-break mb-2 mb-md-0">{{ state.errorMessage }}</span>
    </div>
  </div>
  <div class="alert alert-info" *ngIf="!state.error && state.noAllocationId">
    <div
      class="d-flex flex-column flex-md-row gap-2 align-items-md-center justify-content-between"
    >
      <span class="text-break mb-2 mb-md-0">
        There is no credit line attached to this business manager.
      </span>
    </div>
  </div>
</form>
