import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HelperService } from './helper/helper.service';
import {
  CatalogUserManagementAuditCreateModel,
  CatalogUserManagementAuditListOutModel,
  CatalogUserManagementAuditUpdateModel,
  GetCatalogUserManagementAuditOptions,
} from '../domains/models/catalog-user-management-audit.model';

const params = new HttpParams().set('reset', 'true').set('locale', 'en');

@Injectable({
  providedIn: 'root',
})
export class CatalogUserManagementAuditService {
  catalogManagementAuditRoute: string = `${environment.tppCsToolAPI.url}/v1/catalog-user-management-audit`;

  constructor(
    private httpClient: HttpClient,
    private helperService: HelperService
  ) {}

  getCatalogManagementAudit(
    options: GetCatalogUserManagementAuditOptions
  ): Observable<CatalogUserManagementAuditListOutModel> {
    const { reset, user_fb_id, user_name, catalog_id, valid, offset, limit } =
      options;
    const paramsObject = this.helperService.filterUndefinedProperties({
      reset: reset,
      user_fb_id: user_fb_id,
      user_name: user_name,
      catalog_id: catalog_id,
      valid: valid,
      offset: offset,
      limit: limit,
    });
    const params =
      Object.keys(paramsObject).length > 0
        ? new HttpParams({ fromObject: paramsObject })
        : null;
    return this.httpClient.get<CatalogUserManagementAuditListOutModel>(
      `${this.catalogManagementAuditRoute}/`,
      { params }
    );
  }

  createCatalogManagementAudit(
    catalogManagementAuditCreate: CatalogUserManagementAuditCreateModel
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.catalogManagementAuditRoute}/`,
      catalogManagementAuditCreate
    );
  }

  updateCatalogManagementAudit(
    auditId: string,
    catalogUserManagementAuditUpdateModel: CatalogUserManagementAuditUpdateModel
  ): Observable<any> {
    return this.httpClient.put<any>(
      `${this.catalogManagementAuditRoute}/${auditId}`,
      catalogUserManagementAuditUpdateModel
    );
  }
}
