<div>
    <!-- Step 0: Webhook Settings -->
    <div *ngIf="activeStep === 0" class="fade-in">
        <h5>Webhook Settings</h5>
        <form [formGroup]="form" novalidate>
            <div class="mb-3">
                <label for="webhookUrl" class="form-label">Webhook URL</label>
                <input id="webhookUrl" type="text" class="form-control" formControlName="webhookUrl"
                    placeholder="Enter webhook URL">
            </div>
        </form>
        <button class="btn btn-primary" (click)="nextStep()">Next <i class="bi bi-arrow-right"></i></button>
    </div>

    <!-- Step 1: Address Details -->
    <div *ngIf="activeStep === 1" class="fade-in">
        <h5>Address Details</h5>
        <form [formGroup]="form" novalidate>
            <div class="mb-3">
                <label for="address" class="form-label">Address</label>
                <input id="address" type="text" class="form-control" formControlName="address"
                    placeholder="Enter address">
            </div>
            <div class="mb-3">
                <label for="city" class="form-label">City</label>
                <input id="city" type="text" class="form-control" formControlName="city" placeholder="Enter city">
            </div>
        </form>
        <div>
            <button class="btn btn-outline-secondary me-2" (click)="previousStep()">
                <i class="bi bi-arrow-left"></i> Back
            </button>
            <button class="btn btn-primary" (click)="nextStep()">Next <i class="bi bi-arrow-right"></i></button>
        </div>
    </div>

    <!-- Step 2: Confirmation -->
    <div *ngIf="activeStep === 2" class="fade-in">
        <h5>Confirmation</h5>
        <p>Please review your details:</p>
        <ul class="list-group">
            <li class="list-group-item"><strong>Webhook URL:</strong> {{ form.value.webhookUrl }}</li>
            <li class="list-group-item"><strong>Address:</strong> {{ form.value.address }}</li>
            <li class="list-group-item"><strong>City:</strong> {{ form.value.city }}</li>
        </ul>
        <div class="mt-3">
            <button class="btn btn-outline-secondary me-2" (click)="previousStep()">
                <i class="bi bi-arrow-left"></i> Back
            </button>
            <button class="btn btn-success" (click)="submit()">
                Submit <i class="bi bi-check-lg"></i>
            </button>
        </div>
    </div>
</div>