import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PaginatorComponent } from './paginator/paginator.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchBoxComponent } from './search-box/search-box.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SimpleSearchComponent } from './simple-search/simple-search.component';
import { PrettyPrintPipe } from './pipes/pretty-print.pipe';
import { CustomSlice } from './pipes/customslice.pipe';
import { CheckValuePipe } from './pipes/checkValue.pipe';
import { TransformUndefinedPipe } from './pipes/transformUndefined.pipe';
import { DateAsAgoPipe } from './pipes/date-as-ago.pipe';
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
import { SingleInputModalComponent } from './modals/single-input-modal/single-input-modal.component';
import { SingleDropdownInputModalComponent } from './modals/single-dropdown-input-modal/single-dropdown-input-modal.component';
import { DateDiffinSecondsPipe } from './pipes/date-diffin-seconds.pipe';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { UploadPhotoModalComponent } from './modals/upload-photo-modal/upload-photo-modal.component';
import { FacebookIdValidatorComponent } from './modals/facebook-id-validator/facebook-id-validator.component';
import { StepOneComponent } from './modals/obo-to-shared-modal/step-one/step-one.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { NgStepperModule } from 'angular-ng-stepper';
import { NgProgressModule } from 'ngx-progressbar';
import { StepTwoComponent } from './modals/obo-to-shared-modal/step-two/step-two.component';
import { StepThreeComponent } from './modals/obo-to-shared-modal/step-three/step-three.component';
import { DropdownLinksComponent } from './dropdown-links/dropdown-links.component';
import { ErrorInfoModalComponent } from './modals/error-info-modal/error-info-modal.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { CopyButtonComponent } from './button/copy-button/copy-button.component';
import { SetPaymentMethodModalComponent } from './modals/set-payment-method-modal/set-payment-method-modal.component';
import { SingleDropdownComponent } from './single-dropdown/single-dropdown.component';
import { SubscribeAppModalComponent } from './modals/subscribe-app-modal/subscribe-app-modal.component';
import { MigratePhoneModalComponent } from './modals/migrate-phone-modal/migrate-phone-modal.component';
import { OboToSharedModalComponent } from './modals/obo-to-shared-modal/obo-to-shared-modal.component';
import { StepFourComponent } from './modals/obo-to-shared-modal/step-four/step-four.component';
import { DeletedChannelComponent } from './modals/deleted-channel/deleted-channel.component';
import { ObjectModalComponent } from './modals/object-modal/object-modal.component';
import { NgBootstrapDropdownComponent } from './ng-bootstrap-dropdown/ng-bootstrap-dropdown.component';
import { SendgridComponent } from './modals/sendgrid/sendgrid.component';
import { BlockUserModalComponent } from './modals/block-user-modal/block-user-modal.component';
import { DynamicInputModalComponent } from './modals/dynamic-input-modal/dynamic-input-modal.component';
import { AuditLogComponent } from './components/audit-log/audit-log.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    CdkStepperModule,
    NgStepperModule,
    NgProgressModule,
    NgxJsonViewerModule,
  ],
  declarations: [
    PaginatorComponent,
    SearchBoxComponent,
    DropdownComponent,
    SimpleSearchComponent,
    PrettyPrintPipe,
    CustomSlice,
    CheckValuePipe,
    TransformUndefinedPipe,
    ConfirmationModalComponent,
    DateAsAgoPipe,
    SingleInputModalComponent,
    SingleDropdownInputModalComponent,
    DateDiffinSecondsPipe,
    ToggleSwitchComponent,
    UploadPhotoModalComponent,
    FacebookIdValidatorComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    DropdownLinksComponent,
    CopyButtonComponent,
    ErrorInfoModalComponent,
    SetPaymentMethodModalComponent,
    SingleDropdownComponent,
    SubscribeAppModalComponent,
    MigratePhoneModalComponent,
    OboToSharedModalComponent,
    StepFourComponent,
    DeletedChannelComponent,
    ObjectModalComponent,
    NgBootstrapDropdownComponent,
    SendgridComponent,
    BlockUserModalComponent,
    DynamicInputModalComponent,
    AuditLogComponent,
  ],
  exports: [
    PaginatorComponent,
    SearchBoxComponent,
    DropdownComponent,
    SimpleSearchComponent,
    PrettyPrintPipe,
    CustomSlice,
    CheckValuePipe,
    TransformUndefinedPipe,
    ConfirmationModalComponent,
    DateAsAgoPipe,
    DateDiffinSecondsPipe,
    ToggleSwitchComponent,
    FacebookIdValidatorComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    DropdownLinksComponent,
    CopyButtonComponent,
    NgBootstrapDropdownComponent,
    AuditLogComponent,
  ],
})
export class SharedModule {}
