<div class="shadow">
  <div class="modal-header">
    <h4 class="modal-title">Adding a WABA to a Multi-Partner Solution</h4>
    <button type="button" class="btn-close" (click)="close()"></button>
  </div>
  <div class="modal-body">
    <div class="d-flex flex-column">
      <p>- The WABA cannot already be part of an existing active solution.</p>
      <p>- The destination solution must be in an active state.</p>
    </div>
    <form [formGroup]="form" (ngSubmit)="searchPartner()" class="pb-2">
      <label for="partnerFilter">Partner Name or ID</label>
      <div class="input-group">
        <input
          type="text"
          id="partnerFilter"
          class="form-control"
          formControlName="partnerFilter"
        />
        <div class="input-group-prepend">
          <button
            class="btn btn-primary"
            [disabled]="!form.valid"
            type="submit"
          >
            Check
          </button>
        </div>
      </div>
    </form>
    <div class="py-2" *ngIf="!loadingPartner">
      <div *ngIf="partners && partners.count > 0">
        <h5>Partners</h5>
        <ul class="list-group">
          <li
            class="list-group-item d-flex align-items-center"
            *ngFor="let partner of partners.partners"
          >
            <div
              class="form-check me-2 mb-0"
              *ngIf="partner.settings?.['account_sharing']?.solution_id"
            >
              <input
                class="form-check-input"
                type="checkbox"
                [id]="'checkbox-' + partner.id"
                (change)="onPartnerCheckboxChange($event, partner.id, partner.settings?.['account_sharing']?.solution_id)"
              />
            </div>
            <span>
              <b>Partner Name:</b> {{ partner.name }}&nbsp;
              <b>Partner Solution ID:</b>
              {{ partner.settings?.['account_sharing']?.solution_id ?? 'N/A' }}
            </span>
          </li>
        </ul>
      </div>
      <div *ngIf="partners && partners.count === 0">
        <h5>Partners</h5>
        <ngb-alert type="warning" [dismissible]="false">
          No partner found by the search criteria
        </ngb-alert>
      </div>
    </div>
    <div class="py-2" *ngIf="!loadingWaba">
      <div *ngIf="selectedPartnerId && wabas && wabas.length > 0">
        <h5>
          WABA(s) - total: {{ wabas.length }} selected:
          {{ selectedWaba.length }}
        </h5>
        <div class="p-2 scrollable-container">
          <div class="list-group">
            <div class="list-group-item">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search WABAs by name or ID..."
                  [(ngModel)]="wabaSearchTerm"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>
            <div class="list-group-item" *ngIf="!wabaSearchTerm">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="selectAll"
                  (change)="onSelectAll($event)"
                  [checked]="
                    selectedWaba.length === wabas.length && wabas.length > 0
                  "
                />
                <label class="form-check-label" for="selectAll">
                  SELECT ALL ({{ wabas.length }})
                </label>
              </div>
            </div>
            <div
              class="list-group-item d-flex align-items-center"
              *ngFor="let waba of filteredWabas"
            >
              <div class="form-check me-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  [id]="'waba-' + waba.external_id"
                  [value]="waba.external_id"
                  (change)="onWabaSelect($event, waba.external_id, waba.name)"
                  [checked]="isWabaSelected(waba)"
                />
              </div>
              <div>
                <strong>ID:</strong> {{ waba.external_id }} -
                <strong>Name:</strong> {{ waba.name }}
              </div>
            </div>
            <div
              class="list-group-item text-center text-muted"
              *ngIf="filteredWabas.length === 0"
            >
              No WABAs match your search
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="selectedPartnerId && wabas && wabas.length === 0">
        <h5>WABA(s)</h5>
        <ngb-alert type="warning" [dismissible]="false">
          No wabas found for {{ selectedPartnerId }}
        </ngb-alert>
      </div>
    </div>
    <div *ngIf="wabaProcessingStatus.length > 0" class="pt-2">
      <h5>
        Status - success: <b class="text-success">{{ successCount }}</b> fail:
        <b class="text-danger">{{ failCount }}</b>
      </h5>

      <div class="d-flex gap-2 mb-2">
        <button
          *ngIf="successCount > 0"
          class="btn btn-sm btn-success"
          (click)="exportSuccess()"
          [disabled]="isExporting"
          [attr.aria-label]="'Export successful WABAs'"
        >
          <span *ngIf="!isExporting">Export Success</span>
          <span *ngIf="isExporting">Exporting...</span>
        </button>
        <button
          *ngIf="failCount > 0"
          class="btn btn-sm btn-danger"
          (click)="exportFailed()"
          [disabled]="isExporting"
          [attr.aria-label]="'Export failed WABAs'"
        >
          <span *ngIf="!isExporting">Export Failed</span>
          <span *ngIf="isExporting">Exporting...</span>
        </button>
        <button
          *ngIf="wabaProcessingStatus.length > 0"
          class="btn btn-sm btn-primary"
          (click)="exportAll()"
          [disabled]="isExporting"
          [attr.aria-label]="'Export all WABAs'"
        >
          <span *ngIf="!isExporting">Export All</span>
          <span *ngIf="isExporting">Exporting...</span>
        </button>
      </div>

      <div class="rounded p-2 scrollable-container">
        <ngb-accordion #acc="ngbAccordion">
          <ngb-panel
            *ngFor="let status of wabaProcessingStatus; let i = index"
            [id]="'panel-' + i"
          >
            <ng-template ngbPanelTitle>
              <div class="flex-column align-items-start w-100 status-info">
                <div class="mb-1">
                  <span><strong>ID: </strong>{{ status.id }} </span>
                  <span><strong>Name: </strong>{{ status.name }}</span>
                </div>
                <small
                  class="text-uppercase"
                  [ngClass]="status.success ? 'text-success' : 'text-danger'"
                >
                  {{ status.success ? "Success" : "Fail" }}
                </small>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="d-flex flex-column p-3 bg-light">
                <div class="pt-2 ps-2"><b>Server Response</b></div>
                <div class="p-2">
                  <pre class="json-data">{{ status | json }}</pre>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-danger" (click)="close()">
      Close
    </button>
    <button
      class="btn btn-primary"
      [disabled]="selectedWaba.length <= 0 || !solutionId"
      (click)="startProcess()"
    >
      Proceed
    </button>
  </div>
</div>
