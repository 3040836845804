<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Meta business account info</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="form-group mt-1">
                    <span>Catalog ID</span>
                    <div class="input-group">
                        <input type="text" class="form-control" [(ngModel)]="catalogId">
                        <span class="input-group-append m-0">
                            <button class="btn rr bg-primary text-white" type="button" [disabled]="!catalogId"
                                (click)="getCatalog()">
                                Check
                            </button>
                        </span>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="catalogAssignedUsers">
                <div class="row mt-2">
                    <div class="col-12">
                        <h6 class="fb">Assigned users</h6>
                        <div class="container-fluid mt-2 p-0">
                            <table class="table table-striped table-bordered">
                                <thead class="thead-dark">
                                    <tr>
                                        <th>Name</th>
                                        <th>Tasks</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let user of catalogAssignedUsers.data">
                                        <td>{{ user.name }}</td>
                                        <td>
                                            <ul class="list-unstyled m-0">
                                                <li *ngFor="let task of user.tasks">- {{ task }}</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-center">
                                                <button type="button" class="btn btn-outline-dark"
                                                    ngbTooltip="Remove user from catalog"
                                                    (click)="deleteUserFromCatalog(user.id)"><i
                                                        class="bi bi-trash2-fill"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <h6 class="fb">Assigned user to catalog or update task</h6>
                        <form [formGroup]="form" class="row">
                            <div class="col-12">
                                <label>User</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" [ngbTypeahead]="search"
                                        (selectItem)="setFormValue($event)" (blur)="validateSelection()"
                                        [resultFormatter]="resultFormatter" [inputFormatter]="inputFormatter"
                                        formControlName="user" />
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-primary" type="button" (click)="assignToMe()">
                                            Assign to me
                                        </button>
                                    </div>
                                </div>
                                <div *ngIf="form.get('user')?.hasError('invalidSelection') && form.get('user')?.touched"
                                    class="text-danger">
                                    Please select a valid user from the given options.
                                </div>
                            </div>
                            <div class="col-12 mb-3">
                                <label>Tasks</label>
                                <div class="form-check" *ngFor="let task of availableTasks">
                                    <input class="form-check-input" type="checkbox" id="{{ task }}"
                                        [formControlName]="'task_' + task" (change)="onTaskChange(task, $event)" />
                                    <label class="form-check-label" for="{{ task }}">
                                        {{ task === 'MANAGE' ? 'MANAGE (full permission)' : task }}
                                    </label>
                                </div>
                                <div *ngIf="form.get('tasks')?.hasError('required') && form.get('tasks')?.touched"
                                    class="text-danger mt-1">
                                    Please select at least one task.
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="catalogError">
                <div class="row mt-2">
                    <div class="col-12 text-center">
                        <span class="text-danger">{{ catalogErrorMessage }}</span>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="fetchUserError">
                <div class="row mt-2">
                    <div class="col-12 text-center">
                        <span class="text-danger">{{ fetchUserErrorMessage }}</span>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="assignError">
                <div class="row mt-2">
                    <div class="col-12 text-center">
                        <span class="text-danger">{{ assignErrorMessage }}</span>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-between mt-2">
        <button type="button" class="btn btn-outline-dark" (click)="close()">
            Close
        </button>
        <button type="button" class="btn btn-primary" type="submit" (click)="addUserToCatalog()"
            [disabled]="!form.valid || catalogError || fetchUserError">
            Assign user to catalog
        </button>
    </div>
</div>