import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UserModel } from 'src/app/core/domains/models/user.model';
import {
  GetWabaWebhookOptions,
  WabaWebhookListOutModel,
} from 'src/app/core/domains/models/waba-webhook.model';
import { CloudService } from 'src/app/core/services/cloud.service';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { PltAPIService } from 'src/app/core/services/plt.api.service';
import { SharedDataService } from 'src/app/core/services/shared-data/shared-data.service';
import { WabaHelperService } from 'src/app/core/services/waba-helper.service';

interface Step {
  label: string;
}

const PAGE_SIZE = 3;

@Component({
  selector: 'app-single-scenario',
  templateUrl: './single-scenario.component.html',
  styleUrls: ['./single-scenario.component.scss'],
})
export class SingleScenarioComponent implements OnInit, OnDestroy {
  steps: Step[] = [
    { label: 'Send Test Message' },
    { label: 'View Webhook Logs' },
    { label: 'Send Biz Message' },
    { label: 'Confirm Webhook' },
  ];
  currentStep = 0;
  isTestMessageSent = false;
  webhookValid: boolean = false;
  webhookError: any;
  loadingWebhook: boolean = true;
  bizPhoneNumber: {} = {};
  totalRecords: number = 0;
  page: number = 1;
  pageSize: number = PAGE_SIZE;
  webhookLogs: WabaWebhookListOutModel = {};
  selectedWebhookIndices: boolean[] = [];
  selectedReceivedWebhookIndices: boolean[] = [];
  getWabaWebhookOptions: GetWabaWebhookOptions = {
    offset: '0',
    limit: PAGE_SIZE.toString(),
    reset: true,
  };
  loading: boolean = true;
  wabaLogsError: any = null;
  phoneNumberForm: FormGroup;
  subscriptions: Subscription[] = [];

  messageSent: boolean = false;
  messageError: any;

  constructor(
    private activeModal: NgbActiveModal,
    private pltApiService: PltAPIService,
    private wabaHelperService: WabaHelperService,
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private sharedDataService: SharedDataService,
    private cloudService: CloudService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.getUser();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  pageChanged(page: number): void {
    this.page = page;
    if (this.page != 1) {
      this.getWabaWebhookOptions.offset = (
        (this.page - 1) *
        this.pageSize
      ).toString();
    } else {
      this.getWabaWebhookOptions.offset = '0';
    }
    this.fetchWebhookLogs();
  }

  previousStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  nextStep(): void {
    if (this.isStepValid() && this.currentStep < this.steps.length - 1) {
      this.currentStep++;
      if (this.currentStep === 1 || this.currentStep === 3) {
        this.fetchWebhookLogs();
      }
    }
  }

  isStepValid(): boolean {
    switch (this.currentStep) {
      case 0:
        return this.isTestMessageSent && this.webhookValid;
      case 1:
        return this.selectedWebhookIndices.some((val) => val);
      case 2:
        return this.messageSent;
      case 3:
        return (
          this.selectedReceivedWebhookIndices.filter((val) => val).length >= 2
        );
      default:
        return false;
    }
  }

  completeWizard(): void {
    this.activeModal.close({ completed: true });
  }

  onTestMessageChange(isSent: boolean): void {
    this.isTestMessageSent = isSent;
  }

  onWebhookSelectionChange(selection: {
    index: number;
    checked: boolean;
  }): void {
    this.selectedWebhookIndices[selection.index] = selection.checked;
  }

  onReceivedWebhookSelectionChange(selection: {
    index: number;
    checked: boolean;
  }): void {
    this.selectedReceivedWebhookIndices[selection.index] = selection.checked;
  }

  setSelectedBizNumber(selectedValue): void {
    this.bizPhoneNumber = selectedValue;
    this.checkPhoneNumberWebhook();
  }

  sendMessage(): void {
    this.helperService.showSpinner();
    this.messageError = null;
    this.messageSent = false;
    const body = {
      messaging_product: 'whatsapp',
      to: this.phoneNumberForm.controls['phoneNumber'].value,
      type: 'image',
      image: {
        link: 'https://media.licdn.com/dms/image/v2/D4E0BAQGwo38Hw_LGyg/company-logo_200_200/company-logo_200_200/0/1729605279262/360dialog_logo?e=2147483647&v=beta&t=juzqNCRxaBNsRou2RM0YbWxV7t2z8tfKEHi3c8E_m2Y',
        caption: 'Hello from 360dialog',
      },
    };
    this.cloudService
      .sendCloudApiMessage(
        this.bizPhoneNumber['partnerId'],
        this.bizPhoneNumber['appId'],
        body
      )
      .subscribe({
        error: (err) => {
          this.messageError = err;
          this.helperService.hideSpinner(false);
        },
        complete: () => {
          this.helperService.showSuccessToastr('Message sent successfully!');
          this.messageSent = true;
          this.helperService.hideSpinner(false);
        },
      });
  }

  checkPhoneNumberWebhook(): void {
    this.pltApiService.getCloudWebhook(this.bizPhoneNumber['appId']).subscribe({
      next: (res) => {
        this.webhookValid =
          res?.url ===
          'https://tpp-cs-tool.hub-production.360dialog.io/v1/waba/webhook';
        this.loadingWebhook = false;
      },
      error: (err) => {
        this.webhookError = err;
        this.webhookValid = false;
        this.loadingWebhook = false;
      },
    });
  }

  fetchWebhookLogs(): void {
    this.helperService.showSpinner();
    this.loading = true;
    this.wabaLogsError = null;
    this.wabaHelperService
      .getWabaWebhookLogs(this.getWabaWebhookOptions)
      .subscribe({
        next: (res) => this.handleWebhookResult(res),
        error: (err) => this.handleWebhookError(err),
      });
  }

  handleWebhookResult(res: WabaWebhookListOutModel): void {
    this.webhookLogs = res;
    this.selectedWebhookIndices = this.webhookLogs.data.map(() => false);
    this.selectedReceivedWebhookIndices = this.webhookLogs.data.map(
      () => false
    );
    this.totalRecords = res.total_count;
    this.loading = false;
    this.helperService.hideSpinner(false);
  }

  handleWebhookError(err: any): void {
    this.wabaLogsError = err;
    this.loading = false;
    this.helperService.hideSpinner();
  }

  initializeForm(): void {
    this.phoneNumberForm = this.formBuilder.group({
      phoneNumber: new FormControl('', Validators.required),
    });
  }

  getUser(): void {
    this.subscriptions.push(
      this.sharedDataService.csToolUser$.subscribe((user: UserModel) => {
        if (user?.phone_number) {
          this.phoneNumberForm.patchValue({ phoneNumber: user.phone_number });
        }
      })
    );
  }

  trackById(index: number, item: any): string {
    return item.id || index.toString();
  }

  close(): void {
    this.activeModal.dismiss();
  }
}
