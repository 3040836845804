import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuditLog,
  GetAuditLogsOptions,
} from 'src/app/core/domains/models/audit-logs.model';
import { AuditLogService } from 'src/app/core/services/audit-log.service';
import { HelperService } from 'src/app/core/services/helper/helper.service';

const PAGE_SIZE = 25;
const ERROR_MESSAGE =
  'Fail to load audit logs. Please report the issue to #cs-tool or create a ticket.';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss'],
})
export class AuditLogComponent implements OnInit {
  @Input() clientId: string;
  @Input() partnerId: string;
  page: number = 1;
  pageSize: number = PAGE_SIZE;
  auditLog: AuditLog[] = [];
  getAuditLogsOptions: GetAuditLogsOptions = {
    reset: true,
    sort: '-when',
  };
  loading: boolean = true;
  errorLoading: boolean = false;
  errorLoadingMessage: string = ERROR_MESSAGE;

  constructor(
    private helperService: HelperService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private auditLogService: AuditLogService
  ) {
    this.getAuditLogsOptions.entity_id =
      activatedRoute.snapshot.queryParams?.['entity_id'];
    this.page = +activatedRoute.snapshot.queryParams['page'] || 1;
  }

  ngOnInit(): void {
    this.helperService.showSpinner();
    this.applyFiltersToRoute();
  }

  private loadData(): void {
    this.helperService.showSpinner();
    this.loading = true;
    this.errorLoading = false;
    this.getAuditLogsOptions.entity_id = this.clientId
      ? this.clientId
      : this.partnerId;
    this.auditLogService.getAuditLogs(this.getAuditLogsOptions).subscribe({
      next: (res) => this.handleResult(res),
      error: () => this.handleErrorResults(),
    });
  }

  private handleResult(res: AuditLog[]): void {
    this.auditLog = res;
    this.loading = false;
    this.helperService.hideSpinner();
  }

  private handleErrorResults(): void {
    this.errorLoading = true;
    this.loading = false;
    this.helperService.hideSpinner();
  }

  private applyFiltersToRoute(): void {
    const queryParams = {
      page: this.page,
      activeTab: this.clientId ? 12 : 9,
    };
    if (this.getAuditLogsOptions.entity_id) {
      queryParams['entity_id'] = this.getAuditLogsOptions.entity_id;
    }
    this.router
      .navigate([], {
        queryParams,
        relativeTo: this.activatedRoute,
        replaceUrl: true,
      })
      .then(() => {
        this.loadData();
      });
  }
}
