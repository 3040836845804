import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Allocation } from 'src/app/core/domains/models/allocation.model';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { SharedDataService } from 'src/app/core/services/shared-data/shared-data.service';
import { SpinnerService } from 'src/app/core/services/spinner/spinner.service';
import { DeletedChannelComponent } from 'src/app/shared/modals/deleted-channel/deleted-channel.component';
import { FacebookIdValidatorComponent } from 'src/app/shared/modals/facebook-id-validator/facebook-id-validator.component';
import { MigratePhoneModalComponent } from 'src/app/shared/modals/migrate-phone-modal/migrate-phone-modal.component';
import { SetPaymentMethodModalComponent } from 'src/app/shared/modals/set-payment-method-modal/set-payment-method-modal.component';
import { SubscribeAppModalComponent } from 'src/app/shared/modals/subscribe-app-modal/subscribe-app-modal.component';
import { AttachTagModalComponent } from './modals/attach-tag-modal/attach-tag-modal.component';
import { PltAPIService } from 'src/app/core/services/plt.api.service';
import { SendgridComponent } from 'src/app/shared/modals/sendgrid/sendgrid.component';
import { HubService } from 'src/app/core/services/hub.service';
import { SslCheckComponent } from './modals/ssl-check/ssl-check.component';
import { AuthUserModel } from 'src/app/core/domains/models/auth-user.model';
import { PbsService } from 'src/app/core/services/pbs.service';
import {
  ACTION_ALLOW_OUTBOUND_MESSAGES_MODAL,
  ACTION_DELETE_USER_MODAL,
  ACTION_FLAG_FOR_RE_ONBOARDING_MODAL,
  ACTION_GENERATE_CLIENT_INVOICE_PDF_MODAL,
  ACTION_GENERATE_PAYMENT_LINK_MODAL,
  ACTION_PORT_NUMBER_MODAL,
} from 'src/app/shared/modals/dynamic-input-modal/modals';
import { TppMetaService } from 'src/app/core/services/tpp-meta.service';
import { WabaPhoneNumbersComponent } from './modals/waba-phone-numbers/waba-phone-numbers.component';
import { ChannelInformationComponent } from './modals/channel-information/channel-information.component';
import { PartnerInformationComponent } from './modals/partner-information/partner-information.component';
import { MigrateFlowsComponent } from './modals/migrate-flows/migrate-flows.component';
import { CatalogManagementComponent } from './modals/catalog-management/catalog-management.component';
import { ManageUsersComponent } from 'src/app/shared/modals/waba-manage-users/manage-users.component';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
})
export class ActionComponent implements OnInit, OnDestroy {
  displayColumns: Array<string> = ['NAME', 'DESCRIPTION', 'ACTION'];

  form: FormGroup;
  revokeCreditLineForm: FormGroup;
  migrateTemplatesForm: FormGroup;

  actionError: boolean = false;
  actionMessage: string = '';
  subscriptions: Subscription[] = [];

  allocationIdExists: boolean = false;
  creditLineDeleted: boolean = false;
  allocationObject: Allocation = {};
  verifyObject = {};

  channelId: string = null;
  clientPcrInfo: any = null;

  private readonly ADMIN_REVOKE_CREDIT_LINE = 'admin:revoke-credit-line';
  adminRevokeCreditLine: boolean = false;

  private readonly FINANCE_GENERATE_PAYMENT_LINK =
    'finance:generate-payment-link';
  financeGeneratePaymentLink: boolean = false;

  private readonly FINANCE_GENERATE_PDF_INVOICE =
    'finance:generate-pdf-invoice';
  financeGeneratePDFInvoice: boolean = false;

  constructor(
    private modalService: NgbModal,
    private hubService: HubService,
    private formBuilder: FormBuilder,
    private spinnerService: SpinnerService,
    private pltApiService: PltAPIService,
    private helperService: HelperService,
    private sharedDataService: SharedDataService,
    private pbsService: PbsService,
    private tppMetaService: TppMetaService
  ) {}

  ngOnInit(): void {
    this.checkRoles();
    this.generateForm();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  openModal(modalName, size = 'lg') {
    this.allocationObject = {};
    this.verifyObject = {};
    this.actionError = false;
    this.allocationIdExists = false;
    this.actionMessage = '';
    this.form.reset();
    this.revokeCreditLineForm.reset();
    this.modalService.open(modalName, { centered: true, size: size });
  }

  openFixedModal(type: string): void {
    if (type == 'ssl') {
      this.modalService.open(SslCheckComponent, {
        size: 'md',
        centered: true,
      });
    }
    if (type == 'waba-pn') {
      this.modalService.open(WabaPhoneNumbersComponent, {
        size: 'md',
        centered: true,
      });
    }
    if (type == 'channel-information') {
      this.modalService.open(ChannelInformationComponent, {
        size: 'md',
        centered: true,
      });
    }
    if (type == 'partner-information') {
      this.modalService.open(PartnerInformationComponent, {
        size: 'md',
        centered: true,
      });
    }
    if (type == 'migrate-flows') {
      this.modalService.open(MigrateFlowsComponent, {
        size: 'md',
        centered: true,
      });
    }
    if (type == 'catalog-management') {
      this.modalService.open(CatalogManagementComponent, {
        size: 'lg',
        centered: true,
      });
    }
    if (type == 'waba-user-management') {
      this.modalService.open(ManageUsersComponent, {
        size: 'lg',
        centered: true,
      });
    }
  }

  openModalClear(modalName, formToReset: string = ''): void {
    if (formToReset == 'form') {
      this.clientPcrInfo = null;
      this.form.reset();
    }
    this.modalService.open(modalName, { centered: true, size: 'lg' });
  }

  getClientPcrInfo(): void {
    this.clientPcrInfo = null;
    this.helperService.showSpinner();
    this.hubService
      .getClientPcrInfo(
        this.form.controls['partnerId'].value.trim(),
        this.form.controls['clientId'].value.trim()
      )
      .subscribe({
        next: (res) => (this.clientPcrInfo = res),
        error: (err) =>
          this.helperService.openErrorModal('Fail to get client PCR info', err),
        complete: () => this.helperService.hideSpinner(false),
      });
  }

  reCreateStack(): void {
    this.spinnerService.showBySelector('undeleteStackModal');
    const body = { hub_username: localStorage.getItem('hub_email') ?? '' };
    this.pltApiService
      .reCreateStack(this.form.controls['appId'].value.toString().trim(), body)
      .subscribe({
        error: (err) => {
          this.spinnerService.hideBySelector('undeleteStackModal');
          this.actionError = true;
          this.actionMessage = `Failed to create stack! Error: ${this.helperService.parseErrormessage(
            err
          )}`;
        },
        complete: () => {
          this.spinnerService.hideBySelector('undeleteStackModal');
          this.modalService.dismissAll();
          this.helperService.showSuccessToastr(
            'Stack re-created successfully!'
          );
        },
      });
  }

  deleteUser(): void {
    this.helperService
      .openDynamicInputModal(ACTION_DELETE_USER_MODAL)
      .then((email) => {
        if (email) {
          this.helperService.showSpinner();
          this.hubService.deleteUser(email).subscribe({
            error: (err) =>
              this.helperService.openErrorModal('Fail to delete user', err),
            complete: () =>
              this.helperService.completeWithMessage(
                'User deleted successfully!',
                true
              ),
          });
        }
      });
  }

  portNumber(): void {
    this.helperService
      .openDynamicInputModal(ACTION_PORT_NUMBER_MODAL)
      .then((modalOutput) => {
        if (modalOutput) {
          this.helperService.showSpinner();
          const body = {
            waba_account_id: modalOutput.input2,
            phone_number: modalOutput.input3,
          };
          this.hubService
            .portNumber(modalOutput.input0, modalOutput.input1, body)
            .subscribe({
              error: (err) =>
                this.helperService.openErrorModal('Fail to port number', err),
              complete: () =>
                this.helperService.completeWithMessage(
                  'Number ported successfully!',
                  true
                ),
            });
        }
      });
  }

  generatePaymentLink(): void {
    this.helperService
      .openDynamicInputModal(ACTION_GENERATE_PAYMENT_LINK_MODAL)
      .then((invoiceId) => {
        if (invoiceId) {
          this.helperService.showSpinner();
          this.pbsService.generatePaymentLink(invoiceId).subscribe({
            next: (res) => {
              this.helperService.copyText(
                res?.url,
                'Payment link generated and copied to clipboard!'
              );
              this.helperService.hideSpinner();
            },
            error: (err) =>
              this.helperService.openErrorModal(
                'Fail to generate payment link',
                err
              ),
          });
        }
      });
  }

  generatePDFInvoice(): void {
    this.helperService
      .openDynamicInputModal(ACTION_GENERATE_CLIENT_INVOICE_PDF_MODAL)
      .then((modalOutput) => {
        if (modalOutput) {
          this.helperService.showSpinner();
          this.pbsService
            .generatePDFInvoice(modalOutput.input0, modalOutput.input1)
            .subscribe({
              next: (res) => {
                if (res instanceof Blob) {
                  const blob = new Blob([res], { type: 'application/pdf' });
                  const url = window.URL.createObjectURL(blob);
                  const a = document.createElement('a');
                  a.href = url;
                  a.download = modalOutput.input1;
                  a.click();
                  window.URL.revokeObjectURL(url);
                  this.helperService.hideSpinner();
                } else {
                  this.helperService.showErrorToastr(
                    'File download failed. Invalid response.'
                  );
                  this.helperService.hideSpinner();
                }
              },
              error: (err) =>
                this.helperService.openErrorModal(
                  'Fail to download invoice.',
                  err
                ),
            });
        }
      });
  }

  clear() {
    this.channelId = null;
  }

  flagForReOnboarding(): void {
    this.helperService
      .openDynamicInputModal(ACTION_FLAG_FOR_RE_ONBOARDING_MODAL)
      .then((channelId) => {
        if (channelId) {
          this.helperService.showSpinner();
          this.hubService.flagForReOnboarding(channelId).subscribe({
            error: (err) =>
              this.helperService.openErrorModal(
                'Fail to flag for re-onboarding',
                err
              ),
            complete: () =>
              this.helperService.completeWithMessage(
                'Flagged successfully!',
                true
              ),
          });
        }
      });
  }

  validateFbId() {
    this.modalService.open(FacebookIdValidatorComponent, {
      size: 'lg',
      centered: true,
    });
  }

  openSendgridModal() {
    const modalInstance: NgbModalRef = this.modalService.open(
      SendgridComponent,
      { size: 'xl', centered: true }
    );
    modalInstance.componentInstance.action = true;
  }

  openDeletedChannelModal() {
    this.modalService.open(DeletedChannelComponent, {
      size: 'xl',
      centered: true,
    });
  }

  setPaymentMethod(): void {
    this.modalService.open(SetPaymentMethodModalComponent, { centered: true });
  }

  subscribeApp(): void {
    this.modalService.open(SubscribeAppModalComponent, { centered: true });
  }

  migratePhone(): void {
    this.modalService.open(MigratePhoneModalComponent, { centered: true });
  }

  allowOutboundMessagesForClient(): void {
    this.helperService
      .openDynamicInputModal(ACTION_ALLOW_OUTBOUND_MESSAGES_MODAL)
      .then((modalOutput) => {
        if (modalOutput) {
          this.helperService.showSpinner();
          this.hubService
            .allowOutboundMessages(modalOutput.input0, modalOutput.input1)
            .subscribe({
              error: (err) =>
                this.helperService.openErrorModal(
                  'Fail to allow outbound messages',
                  err
                ),
              complete: () =>
                this.helperService.completeWithMessage(
                  `Outbound messages allowed for client ${modalOutput.input1}`,
                  true
                ),
            });
        }
      });
  }

  migrateTemplates(): void {
    this.helperService.showSpinner();
    this.tppMetaService
      .migrateTemplates(
        this.migrateTemplatesForm.controls['destination_waba'].value.trim(),
        this.migrateTemplatesForm.controls['source_waba'].value.trim()
      )
      .subscribe({
        error: (err) => {
          this.helperService.openErrorModal('Fail to migrate templates.', err);
          this.migrateTemplatesForm.reset();
        },
        complete: () => {
          this.helperService.completeWithMessage(
            'Templates migrated successfully.',
            true
          );
        },
      });
  }

  isObjectEmpty(obj): boolean {
    return this.helperService.isObjectEmpty(obj);
  }

  getAllocationId(): void {
    this.helperService.showSpinner();
    this.actionError = false;
    this.actionMessage = '';
    this.allocationIdExists = false;
    this.creditLineDeleted = false;
    this.tppMetaService
      .getAllocationId(
        this.revokeCreditLineForm.controls['business_manager_id'].value
      )
      .subscribe({
        next: (res) => {
          if (this.helperService.isObjectEmpty(res?.data)) {
            this.actionError = true;
            this.actionMessage =
              'The allocation object is empty meaning that the credit line is probably not attached';
          } else {
            this.allocationObject = res?.data?.[0];
            this.revokeCreditLineForm
              .get('allocation_id')
              .setValue(this.allocationObject.id);
            this.allocationIdExists = true;
          }
          this.helperService.hideSpinner(false);
        },
        error: (err) => {
          this.revokeCreditLineForm.reset();
          this.helperService.openErrorModal(
            'Fail to fetch allocation ID.',
            err
          );
        },
      });
  }

  deleteCreditLine(): void {
    this.helperService.showSpinner();
    this.actionError = false;
    this.actionMessage = '';
    this.creditLineDeleted = false;
    this.tppMetaService
      .deleteCreditLine(
        this.revokeCreditLineForm.controls['allocation_id'].value
      )
      .subscribe({
        error: (err) => {
          this.revokeCreditLineForm.reset();
          this.helperService.openErrorModal('Fail to delete credit line.', err);
        },
        complete: () => {
          this.helperService.showSuccessToastr(
            'Credit line removed successfully.'
          );
          this.verifyDeleted();
        },
      });
  }

  verifyDeleted(): void {
    this.tppMetaService
      .verifyCreditLineDeleted(
        this.revokeCreditLineForm.controls['allocation_id'].value
      )
      .subscribe({
        next: (res) => {
          this.verifyObject = res?.data;
          this.creditLineDeleted = true;
          this.helperService.hideSpinner(false);
        },
      });
  }

  attachTag(): void {
    this.modalService.open(AttachTagModalComponent, { centered: true });
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({
      userEmail: new FormControl(null, [Validators.required]),
      appId: new FormControl(null),
      partnerId: new FormControl(null),
      clientId: new FormControl(null),
      wabaAccId: new FormControl(null),
      phoneNumber: new FormControl(null),
      userPassword: new FormControl(null),
    });

    this.revokeCreditLineForm = this.formBuilder.group({
      business_manager_id: new FormControl('', [Validators.required]),
      allocation_id: new FormControl('', [Validators.required]),
    });

    this.migrateTemplatesForm = this.formBuilder.group({
      source_waba: new FormControl('', [Validators.required]),
      destination_waba: new FormControl('', [Validators.required]),
    });
  }

  private checkRoles() {
    this.subscriptions.push(
      this.sharedDataService.user$.subscribe((user: AuthUserModel) => {
        this.adminRevokeCreditLine = this.helperService.checkPermission(
          user,
          this.ADMIN_REVOKE_CREDIT_LINE
        );
        this.financeGeneratePaymentLink = this.helperService.checkPermission(
          user,
          this.FINANCE_GENERATE_PAYMENT_LINK
        );
        this.financeGeneratePDFInvoice = this.helperService.checkPermission(
          user,
          this.FINANCE_GENERATE_PDF_INVOICE
        );
      })
    );
  }
}
