import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SharedDataService } from '../core/services/shared-data/shared-data.service';
import { HelperService } from '../core/services/helper/helper.service';
import { AuthUserModel } from '../core/domains/models/auth-user.model';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private sharedDataService: SharedDataService,
    private helperService: HelperService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const requiredRole = route.data['requiredRole'];

    return this.sharedDataService.user$.pipe(
      take(1),
      map((user: AuthUserModel) => {
        if (this.helperService.checkPermission(user, requiredRole)) {
          return true;
        } else {
          this.helperService.showErrorToastr(
            "You don't have permission to access this page"
          );
          this.router.navigate(['/']);
          return false;
        }
      })
    );
  }
}
