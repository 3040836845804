<div>
    <div class="d-flex flex-column mb-3">
        <h6 class="fb">Step 1 - Send Test Message</h6>
        <ol class="list-group">
            <li class="list-group-item">
                1. Enter your phone number that will be used in this test scenario
            </li>
            <div class="p-2">
                <form [formGroup]="phoneForm">
                    <div class="form-group">
                        <label>Phone Number (with country code)</label>
                        <input type="text" class="form-control" formControlName="phoneNumber">
                    </div>

                </form>
            </div>
            <li class="list-group-item">
                2. Select the biz number
            </li>
            <div class="p-2">
                <select class="form-select" [(ngModel)]="selectedBizNumber"
                    (ngModelChange)="onSelectedBizNumber($event)">
                    <option [ngValue]="null" disabled>-- Select biz phone number --</option>
                    <option *ngFor="let phoneNumber of internalPhoneNumbers" [ngValue]="phoneNumber">
                        {{ phoneNumber.phoneNumber }}
                    </option>
                </select>

            </div>
            <div *ngIf="qrUrl">
                <li class="list-group-item">
                    3. Scan the QR code provided with your phone
                </li>
                <div class="text-center py-2">
                    <img [src]="qrUrl" alt="QR Code">
                </div>
                <li class="list-group-item d-flex">
                    <div class="form-check ms-2">
                        <input class="form-check-input" type="checkbox" [checked]="isTestMessageSent"
                            (change)="onCheckboxChange($event)">
                        <label class="form-check-label">
                            4. Send the test message and mark this action as completed.
                        </label>
                    </div>
                </li>
            </div>
        </ol>
    </div>
</div>