import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StackService {
  stackRoute: string = `${environment.tppCsToolAPI.url}/stack`;

  constructor(private httpClient: HttpClient) {}

  private createHeaders(token: string): HttpHeaders {
    return new HttpHeaders({
      'Stack-Token': token,
    });
  }

  // # POST

  generateAuthToken(
    appId: string,
    username: string,
    password: string
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.stackRoute}/token/app/${appId}/username/${username}/password/${password}`,
      {}
    );
  }

  generateBackupFile(appId: string, token: string, body: any): Observable<any> {
    const headers = this.createHeaders(token);
    return this.httpClient.post<any>(
      `${this.stackRoute}/app/${appId}/settings/backup`,
      body,
      {
        headers: headers,
      }
    );
  }

  enableMfa(appId: string, token: string, body: any): Observable<any> {
    const headers = this.createHeaders(token);
    return this.httpClient.post<any>(
      `${this.stackRoute}/app/${appId}/settings/account/two-step`,
      body,
      {
        headers: headers,
      }
    );
  }

  registerStack(appId: string, token: string, body: any): Observable<any> {
    const headers = this.createHeaders(token);
    return this.httpClient.post<any>(
      `${this.stackRoute}/app/${appId}/account`,
      body,
      {
        headers: headers,
      }
    );
  }

  registerStackWipe(appId: string, token: string, body: any): Observable<any> {
    const headers = this.createHeaders(token);
    return this.httpClient.post<any>(
      `${this.stackRoute}/app/${appId}/account`,
      body,
      {
        headers: headers,
      }
    );
  }

  verifyStack(appId: string, token: string, body: any): Observable<any> {
    const headers = this.createHeaders(token);
    return this.httpClient.post<any>(
      `${this.stackRoute}/app/${appId}/account/verify`,
      body,
      {
        headers: headers,
      }
    );
  }

  // # PUT
  executeGCMedia(appId: string, token: string): Observable<any> {
    const headers = this.createHeaders(token);
    return this.httpClient.put<any>(
      `${this.stackRoute}/app/${appId}/services/media/gc`,
      {},
      {
        headers: headers,
      }
    );
  }

  executeGCMessage(appId: string, token: string): Observable<any> {
    const headers = this.createHeaders(token);
    return this.httpClient.put<any>(
      `${this.stackRoute}/app/${appId}/services/message/gc`,
      {},
      {
        headers: headers,
      }
    );
  }

  // # PATCH
  updateAutoGC(appId: string, token: string, body: any): Observable<any> {
    const headers = this.createHeaders(token);
    return this.httpClient.patch<any>(
      `${this.stackRoute}/app/${appId}/settings/application`,
      body,
      {
        headers: headers,
      }
    );
  }
  // # DELETE

  disableMfa(appId: string, token: string): Observable<any> {
    const headers = this.createHeaders(token);
    return this.httpClient.delete<any>(
      `${this.stackRoute}/app/${appId}/settings/account/two-step`,
      {
        headers: headers,
      }
    );
  }
}
