import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WabaWebhookListOutModel } from 'src/app/core/domains/models/waba-webhook.model';

@Component({
  selector: 'app-confirm-webhook-step',
  templateUrl: './confirm-webhook-step.component.html',
  styleUrls: ['./confirm-webhook-step.component.scss'],
})
export class ConfirmWebhookStepComponent {
  @Input() webhookLogs: WabaWebhookListOutModel;
  @Input() selectedIndices: boolean[];
  @Input() phoneNumber: string;
  @Output() selectionChange = new EventEmitter<{
    index: number;
    checked: boolean;
  }>();
  @Output() fetchWebhookLogs = new EventEmitter();
  @Output() fetchNewPage = new EventEmitter<number>();

  page: number = 1;
  pageSize: number = 3;

  toggleSelection(index: number): void {
    const newChecked = !this.selectedIndices[index];
    this.selectionChange.emit({ index, checked: newChecked });
  }

  refreshWebhookLogs(): void {
    this.fetchWebhookLogs.emit();
  }

  trackById(index: number, item: any): string {
    return item.id || index.toString();
  }

  hasMessageFrom(payload: any, recipientId: string): boolean {
    if (!payload || !Array.isArray(payload.entry)) {
      return false;
    }
    return payload.entry.some((entry) =>
      entry.changes?.some((change) =>
        change.value?.statuses?.some(
          (status) =>
            status.recipient_id === recipientId &&
            (status.status === 'sent' || status.status === 'delivered')
        )
      )
    );
  }

  pageChanged(page: number): void {
    this.page = page;
    this.fetchNewPage.emit(page);
  }
}
