<div class="container-fluid mt-3">
    <div class="row">
        <div class="col-md-12">
            <ngb-accordion #acc="ngbAccordion" *ngIf="!loading && auditLog?.length > 0">
                <ngb-panel *ngFor="let log of auditLog; let i = index" [id]="'panel-' + i">
                    <ng-template ngbPanelTitle>
                        <div class="flex-column align-items-start w-100">
                            <h6 class="mb-1">{{ log.who?.user_name || 'Unknown User' }}</h6>
                            <p class="mb-1">{{ log.type }}</p>
                            <small class="text-muted">
                                {{ log.when | date:'medium' }}
                            </small>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="d-flex flex-column p-3 bg-light">
                            <div class="p-2"><b>Entity ID:</b> {{ log.what?.entity_id || 'N/A' }}</div>
                            <div class="pt-2 ps-2"><b>Dynamic Data</b></div>
                            <div class="p-2">
                                <pre class="json-data">{{ log.what?.dynamic_data | json }}</pre>
                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <ngb-alert [dismissible]="false" type="info" class="text-center" *ngIf="loading">
                Loading logs....
            </ngb-alert>
            <ngb-alert [dismissible]="false" class="text-center" *ngIf="!loading && auditLog?.length == 0">
                There are no logs created.
            </ngb-alert>
        </div>
    </div>
</div>