import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ActionComponent } from './components/action/action.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { LoginComponent } from './components/login/login.component';

const routes: Routes = [
  { path: '', redirectTo: 'phone', pathMatch: 'full' },
  {
    path: 'phone',
    loadChildren: () =>
      import('./components/phone/phone.module').then(
        (module) => module.PhoneModule
      ),
  },
  {
    path: 'partners',
    loadChildren: () =>
      import('./components/partner/partner.module').then(
        (module) => module.PartnerModule
      ),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./components/admin/admin.module').then(
        (module) => module.AdminModule
      ),
  },
  {
    path: 'known-issues',
    loadChildren: () =>
      import('./components/known-issues/known-issues.module').then(
        (module) => module.KnownIssuesModule
      ),
  },
  {
    path: 'tos',
    loadChildren: () =>
      import('./components/tos/tos.module').then((module) => module.TosModule),
  },
  {
    path: 'review',
    loadChildren: () =>
      import('./components/review/review.module').then(
        (module) => module.ReviewModule
      ),
  },
  {
    path: 'partner-escalations',
    loadChildren: () =>
      import(
        './components/partner-escalations/partner-escalations.module'
      ).then((module) => module.PartnerEscalationsModule),
  },
  {
    path: 'react-view',
    loadChildren: () =>
      import('./components/react-view/react-view.module').then(
        (module) => module.ReactViewModule
      ),
  },
  { path: 'action', component: ActionComponent },
  { path: 'login', component: LoginComponent },
  { path: 'not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
