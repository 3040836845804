import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { SingleScenarioComponent } from './single-scenario/single-scenario.component';
import { DoubleScenarioComponent } from './double-scenario/double-scenario.component';

@Component({
  selector: 'app-test-waba',
  templateUrl: './test-waba.component.html',
  styleUrls: ['./test-waba.component.scss'],
})
export class TestWabaComponent {
  selectedScenario: string = '';

  constructor(
    private activeModal: NgbActiveModal,
    private helperService: HelperService
  ) {}

  onScenarioSelected(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    if (selectedValue) {
      this.helperService.dismissModal();
      if (selectedValue === 'scenario1') {
        this.helperService.openModal(SingleScenarioComponent, 'lg');
      } else {
        this.helperService.openModal(DoubleScenarioComponent, 'lg');
      }
    }
  }

  close(): void {
    this.activeModal.dismiss();
  }
}
