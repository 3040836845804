import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  SingleSearchTypeEnum,
  MultiSearchTypeEnum,
  PartnerSearchEnum,
} from 'src/app/core/domains/enums/search-type.enum';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit, OnChanges {
  @Input() searchType: string = '';
  @Input() partnerSearch?: boolean = false;
  @Input() trim?: boolean = false;
  @Output() searchParameters = new EventEmitter<any>();
  @Output() selectedDropdown = new EventEmitter<string>();

  form: FormGroup;
  searchTypes: string[];
  selectedSearchType: string = '';

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.setDropdown();
  }

  ngOnChanges(changes): void {
    if (changes.searchType) {
      this.setDropdown();
    }
  }

  deletInput(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.form.get('searchInput').reset();
    }
  }

  setDropdown() {
    if (this.partnerSearch) {
      this.form = this.formBuilder.group({
        searchInput: new FormControl(null, [Validators.required]),
        searchType: new FormControl(
          Object.keys(PartnerSearchEnum).find(
            (key) => PartnerSearchEnum[key] === 'Partner Id'
          ),
          []
        ),
      });
      this.searchTypes = Object.values(PartnerSearchEnum);
      this.selectedSearchType = this.searchTypes[0];
    } else {
      if (this.searchType == 'multi') {
        this.form = this.formBuilder.group({
          searchInput: new FormControl(null, [Validators.required]),
          searchType: new FormControl(
            Object.keys(MultiSearchTypeEnum).find(
              (key) => MultiSearchTypeEnum[key] === 'Phone Number'
            ),
            []
          ),
        });
        this.searchTypes = Object.values(MultiSearchTypeEnum);
        this.selectedSearchType = this.searchTypes[0];
      } else {
        this.form = this.formBuilder.group({
          searchInput: new FormControl(null, [Validators.required]),
          searchType: new FormControl(
            Object.keys(SingleSearchTypeEnum).find(
              (key) => SingleSearchTypeEnum[key] === 'Phone Number'
            ),
            []
          ),
        });
        this.searchTypes = Object.values(SingleSearchTypeEnum);
        this.selectedSearchType = this.searchTypes[0];
      }
    }
  }

  onSubmit(): void {
    if (this.form.value.searchInput) {
      const excludedTypes = [
        'projectName',
        'wabaName',
        'clientEmail',
        'clientName',
      ];
      if (!excludedTypes.includes(this.form.value.searchType)) {
        let input = this.form.value.searchInput;
        if (!this.partnerSearch) {
          input = input.replace(/[\s\/\\,+\-]/g, '');
        }
        if (this.trim) {
          input = input.trim();
        }
        this.form.controls['searchInput'].setValue(input);
      }
      this.searchParameters.emit(this.form.value);
    }
  }

  setSearch(inputString) {
    const appIdRegex: RegExp = /^\d{3,6}$/;
    const phoneNumberRegex: RegExp = /^\d{7,14}$/;
    const fbRegex: RegExp = /^\d{15}$/;
    const partnerIdRegex: RegExp = /^.*PA$/;
    const clientIdRegex: RegExp = /^.*CL$/;
    const waRegex: RegExp = /^.*WA$/;
    const emailRegex: RegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!!inputString) {
      if (this.partnerSearch) {
        if (partnerIdRegex.test(inputString)) {
          this.selectedSearchType = this.searchTypes[0];
        } else {
          this.selectedSearchType = this.searchTypes[1];
        }
        return;
      }
      if (inputString.endsWith('CH')) {
        this.selectedSearchType = this.searchTypes[11];
      } else if (appIdRegex.test(inputString)) {
        this.selectedSearchType = this.searchTypes[1];
      } else if (phoneNumberRegex.test(inputString)) {
        this.selectedSearchType = this.searchTypes[0];
      } else if (fbRegex.test(inputString)) {
        this.selectedSearchType = this.searchTypes[2];
      } else if (partnerIdRegex.test(inputString)) {
        this.selectedSearchType = this.searchTypes[6];
      } else if (clientIdRegex.test(inputString)) {
        this.selectedSearchType = this.searchTypes[7];
      } else if (emailRegex.test(inputString)) {
        this.selectedSearchType = this.searchTypes[9];
      } else if (waRegex.test(inputString)) {
        this.selectedSearchType = this.searchTypes[5];
      }
    }
  }

  searchTypeSelected(searchType: string) {
    if (this.partnerSearch) {
      searchType = Object.keys(PartnerSearchEnum).find(
        (key) => PartnerSearchEnum[key] === searchType
      );
    } else {
      if (this.searchType == 'multi') {
        searchType = Object.keys(MultiSearchTypeEnum).find(
          (key) => MultiSearchTypeEnum[key] === searchType
        );
      } else {
        searchType = Object.keys(SingleSearchTypeEnum).find(
          (key) => SingleSearchTypeEnum[key] === searchType
        );
      }
    }

    this.form.controls['searchType'].setValue(searchType);
    this.selectedDropdown.emit(searchType);
  }
}
