<!-- <div class="container-fluid p-0">
    <div class="wizard-card shadow-lg">
        <div class="wizard-header position-relative">
            <button type="button" class="btn-close position-absolute top-0 end-0 m-2" (click)="close()"></button>
            <h3 class="mb-0">WABA Test</h3>
            <p class="lead mb-0">Scenario 1</p>
        </div>
        <div class="wizard-progress px-4 py-2">
            <div class="progress">
                <div class="progress-bar" role="progressbar" [style.width.%]="((activeStep + 1) / steps.length) * 100"
                    aria-valuemin="0" aria-valuemax="100">
                </div>
            </div>
        </div>
        <div class="wizard-steps d-flex justify-content-between px-4 mb-3">
            <div *ngFor="let step of steps; let i = index" class="wizard-step" [class.active]="activeStep === i">
                <div class="step-number">{{ i + 1 }}</div>
                <div class="step-label">{{ step.label }}</div>
            </div>
        </div>
        <div class="wizard-content p-4">
            <div *ngIf="activeStep === 0" class="fade-in">
                <div>
                    <div>
                        <div class="d-flex flex-column mb-3">
                            <h6 class="fb">Step 1 - Send message to business</h6>
                            <ol class="list-group list-group-numbered">
                                <li class="list-group-item">
                                    Scan the QR code provided with your phone
                                </li>
                                <div class="text-center py-2">
                                    <img [src]="generateQRCodeUrl()">
                                </div>
                                <li class="list-group-item d-flex">
                                    <div class="form-check ms-2">
                                        <input class="form-check-input" type="checkbox" [checked]="stepOneCompleted"
                                            (change)="onTestMessageCheckboxChange($event)">
                                        <label class="form-check-label" for="flexCheckChecked">
                                            Send the test message and mark this action as completed.
                                        </label>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div *ngIf="(!webhookValid || webhookError) && !loadingWebhook">
                    <ngx-json-viewer [json]="webhookError"></ngx-json-viewer>
                </div>
            </div>
            <div *ngIf="activeStep === 1" class="fade-in">
                <div class="d-inline-flex mb-3 align-items-center">
                    <h6 class="fb">Step 2 - Webhook</h6>
                    <button type="button" class="btn-custom ms-2" ngbTooltip="Fetch webhook logs"
                        (click)="getWabaLogs()">
                        <img src="../../../assets/images/icons/refresh-icon.svg" alt="refresh-icon" />
                    </button>
                </div>
                <ul class="list-group">
                    <li class="list-group-item" *ngFor="let webhook of wabaWebhookListOutModel.data; let i = index">
                        <div class="form-check scrollable-container">
                            <input class="form-check-input" type="checkbox" [checked]="webhookChecked[i]"
                                (change)="onWebhookCheckboxChange($event, i)">
                            <ngx-json-viewer class="webhook-logs" [json]="webhook.payload"
                                [expanded]="true"></ngx-json-viewer>
                        </div>
                    </li>
                </ul>
            </div>
            <div *ngIf="activeStep === 2" class="fade-in">
                <h6 class="fb mb-3">Step 3 - Receive message from biz</h6>
                <form [formGroup]="phoneNumberForm">
                    <label>Phone Number (with CC)</label>
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="phoneNumber">
                        <div class="input-group-prepend">
                            <button class="btn btn-primary" type="submit" [disabled]="!phoneNumberForm.valid"
                                (click)="sendMessage()">Send message</button>
                        </div>
                    </div>
                </form>
            </div>
            <div *ngIf="activeStep === 3" class="fade-in">
                <div class="d-inline-flex mb-3 align-items-center">
                    <h6 class="fb">Step 4 - Webhook</h6>
                    <button type="button" class="btn-custom ms-2" ngbTooltip="Fetch webhook logs"
                        (click)="getWabaLogs()">
                        <img src="../../../assets/images/icons/refresh-icon.svg" alt="refresh-icon" />
                    </button>
                </div>
                <ul class="list-group">
                    <li class="list-group-item" *ngFor="let webhook of wabaWebhookListOutModel.data; let i = index">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" [checked]="webhookReceivedChecked[i]"
                                (change)="onWebhookReceivedCheckboxChange($event, i)">
                            <ngx-json-viewer class="webhook-logs" [json]="webhook.payload"
                                [expanded]="false"></ngx-json-viewer>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="wizard-footer px-4 py-3 d-flex justify-content-between align-items-center">
            <button class="btn btn-outline-secondary" (click)="previousStep()" [disabled]="activeStep === 0">
                <i class="bi bi-arrow-left"></i> Back
            </button>
            <div>
                <button class="btn btn-primary me-2" *ngIf="activeStep < steps.length - 1" (click)="nextStep()"
                    [disabled]="!isCurrentStepValid()">
                    Next <i class="bi bi-arrow-right"></i>
                </button>
                <button class="btn btn-success me-2" *ngIf="activeStep === steps.length - 1" (click)="close()"
                    [disabled]="!isCurrentStepValid()">
                    Complete <i class="bi bi-check"></i>
                </button>
            </div>
        </div>
    </div>
</div> -->

<div class="container-fluid p-0">
    <div class="wizard-card shadow-lg">
        <div class="wizard-header">
            <button type="button" class="btn-close position-absolute top-0 end-0 m-2" (click)="close()"></button>
            <h3 class="mb-0">WABA Test</h3>
            <p class="lead mb-0">Scenario 1</p>
        </div>

        <div class="wizard-progress px-4 py-2">
            <div class="progress">
                <div class="progress-bar" role="progressbar" [style.width.%]="((currentStep + 1) / steps.length) * 100">
                </div>
            </div>
        </div>

        <div class="wizard-steps d-flex justify-content-between px-4 mb-3">
            <div *ngFor="let step of steps; let i = index" class="wizard-step" [class.active]="currentStep === i">
                <div class="step-number">{{ i + 1 }}</div>
                <div class="step-label">{{ step.label }}</div>
            </div>
        </div>

        <div class="wizard-content p-4">
            <ng-container *ngIf="currentStep === 0">
                <app-send-message-step [phoneForm]="phoneNumberForm" [isTestMessageSent]="isTestMessageSent"
                    (setSelectedBizNumber)="setSelectedBizNumber($event)"
                    (testMessageChange)="onTestMessageChange($event)">
                </app-send-message-step>
                <div *ngIf="(!webhookValid || webhookError) && !loadingWebhook">
                    <ngx-json-viewer [json]="webhookError"></ngx-json-viewer>
                </div>
            </ng-container>

            <ng-container *ngIf="currentStep === 1">
                <app-sent-webhook-step [webhookLogs]="webhookLogs" (fetchNewPage)="pageChanged($event)"
                    [selectedIndices]="selectedWebhookIndices" (fetchWebhookLogs)="fetchWebhookLogs()"
                    (selectionChange)="onWebhookSelectionChange($event)"
                    [phoneNumber]="phoneNumberForm.controls['phoneNumber'].value">
                </app-sent-webhook-step>
            </ng-container>

            <ng-container *ngIf="currentStep === 2">
                <app-receive-message-step [phoneForm]="phoneNumberForm" (sendMessage)="sendMessage()">
                </app-receive-message-step>
            </ng-container>

            <ng-container *ngIf="currentStep === 3">
                <app-confirm-webhook-step [webhookLogs]="webhookLogs"
                    [phoneNumber]="phoneNumberForm.controls['phoneNumber'].value"
                    (fetchWebhookLogs)="fetchWebhookLogs()" (fetchNewPage)="pageChanged($event)"
                    [selectedIndices]="selectedReceivedWebhookIndices"
                    (selectionChange)="onReceivedWebhookSelectionChange($event)">
                </app-confirm-webhook-step>
            </ng-container>
        </div>

        <div class="wizard-footer">
            <button class="btn btn-outline-secondary" (click)="previousStep()" [disabled]="currentStep === 0">
                <i class="bi bi-arrow-left"></i> Back
            </button>

            <button class="btn btn-primary me-2" *ngIf="currentStep < steps.length - 1" (click)="nextStep()"
                [disabled]="!isStepValid()">
                Next <i class="bi bi-arrow-right"></i>
            </button>

            <button class="btn btn-success" *ngIf="currentStep === steps.length - 1" (click)="completeWizard()"
                [disabled]="!isStepValid()">
                Complete <i class="bi bi-check"></i>
            </button>
        </div>
    </div>
</div>