import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import * as React from 'react';
import { createRoot, Root } from 'react-dom/client'; // New React 18 API

@Component({
  selector: 'app-react-wrapper',
  template: '<div id="react-root"></div>',
})
export class ReactWrapperComponent implements OnInit, OnDestroy {
  @Input() component!: React.ComponentType<any>; // React component to render
  @Input() props: any = {}; // Props to pass to the React component
  private root!: Root; // Reference to the React root

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    // Create a React root using createRoot
    const container =
      this.elementRef.nativeElement.querySelector('#react-root');
    this.root = createRoot(container);
    this.root.render(React.createElement(this.component, this.props));
  }

  ngOnDestroy() {
    // Unmount the React component using the root
    if (this.root) {
      this.root.unmount();
    }
  }
}
