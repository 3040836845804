<div>
    <div class="d-inline-flex mb-3 align-items-center">
        <h6 class="fb">Step 4 - Biz Webhook</h6>
        <button type="button" class="btn-custom ms-2" ngbTooltip="Fetch webhook logs" (click)="refreshWebhookLogs()">
            <img src="../../../assets/images/icons/refresh-icon.svg" alt="refresh-icon" />
        </button>
    </div>
    <ol class="list-group mb-2">
        <li class="list-group-item">
            1. Search for the webhook log containing `sent` && `delivered` and select them.
        </li>
    </ol>
    <ul class="list-group">
        <li class="list-group-item" *ngFor="let webhook of webhookLogs.data; let i = index; trackBy: trackById">
            <div class="scrollable-container" (click)="toggleSelection(i)">
                <pre class="json-display"
                    [ngClass]="{'selected': selectedIndices[i], 'info-border': !selectedIndices[i] && hasMessageFrom(webhook.payload, phoneNumber) }">
  {{ webhook.payload | json }}
</pre>

            </div>
        </li>
    </ul>

    <div class="row m-0 p-0" *ngIf="webhookLogs?.total_count > 3">
        <div class="col">
            <app-paginator (inputPageEvent)="pageChanged($event)" [pageSize]="pageSize" [page]="page"
                [collectionSize]="webhookLogs?.total_count"></app-paginator>
        </div>
    </div>
</div>