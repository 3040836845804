import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { TppMetaService } from 'src/app/core/services/tpp-meta.service';

@Component({
  selector: 'app-waba-phone-numbers',
  templateUrl: './waba-phone-numbers.component.html',
})
export class WabaPhoneNumbersComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private tppMetaService: TppMetaService
  ) {}

  form: any;
  response: any;

  ngOnInit(): void {
    this.generateForm();
  }

  close(): void {
    this.helperService.dismissModal();
  }

  confirm(): void {
    this.helperService.showSpinner();
    this.tppMetaService
      .getPhoneNumbers(this.form.controls.wabaId.value)
      .subscribe({
        next: (res) => {
          this.response = res;
          this.helperService.hideSpinner(false);
        },
        error: (error) =>
          this.helperService.openErrorModal(
            'Fail to fetch phone numbers',
            error
          ),
      });
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({
      wabaId: new FormControl('', [Validators.required]),
    });
  }
}
