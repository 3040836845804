import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { IntercomConversationModel } from '../domains/models/intercom.model';
import {
  GetIntercomReviewsOptions,
  IntercomReviewListOutModel,
} from '../domains/models/intercom-review.model';
import {
  GetPartnerEscalationOptions,
  PartnerEscalation,
} from '../domains/models/partner-escalation.model';
import { HelperService } from './helper/helper.service';

const params = new HttpParams().set('reset', 'true');

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  intercomRoute: string = `${environment.tppCsToolAPI.url}/v1/intercom`;

  constructor(
    private httpClient: HttpClient,
    private helperService: HelperService
  ) {}

  getConversation(
    conversationId: string
  ): Observable<IntercomConversationModel> {
    return this.httpClient.get<IntercomConversationModel>(
      `${this.intercomRoute}/conversations/${conversationId}`,
      { params }
    );
  }
  updateConversation(conversationId: string, body: {}): Observable<any> {
    return this.httpClient.post<any>(
      `${this.intercomRoute}/conversations/${conversationId}`,
      body
    );
  }

  downloadReviewDocument() {
    return this.httpClient.get(`${this.intercomRoute}/review/download`, {
      responseType: 'blob',
    });
  }

  getReviews(
    options: GetIntercomReviewsOptions
  ): Observable<IntercomReviewListOutModel> {
    const { reset, offset, limit } = options;
    const paramsObject = this.helperService.filterUndefinedProperties({
      reset: reset,
      offset: offset,
      limit: limit,
    });
    const params =
      Object.keys(paramsObject).length > 0
        ? new HttpParams({ fromObject: paramsObject })
        : null;
    return this.httpClient.get<IntercomReviewListOutModel>(
      `${this.intercomRoute}/review`,
      { params }
    );
  }

  getPartnerEscalation(
    options: GetPartnerEscalationOptions = {}
  ): Observable<PartnerEscalation> {
    const { reset, partner_id, offset, limit, from_date, to_date, resolved } =
      options;
    const paramsObject = this.helperService.filterUndefinedProperties({
      reset: reset,
      partner_id: partner_id,
      offset: offset,
      limit: limit,
      from_date: from_date,
      to_date: to_date,
      resolved: resolved,
    });
    const params =
      Object.keys(paramsObject).length > 0
        ? new HttpParams({ fromObject: paramsObject })
        : null;
    return this.httpClient.get<PartnerEscalation>(
      `${this.intercomRoute}/partner-escalation`,
      { params }
    );
  }

  downloadPartnerEscalations(options: GetPartnerEscalationOptions = {}) {
    const { partner_id, from_date, to_date, resolved } = options;
    const paramsObject = this.helperService.filterUndefinedProperties({
      partner_id: partner_id,
      from_date: from_date,
      to_date: to_date,
      resolved: resolved,
    });
    const params =
      Object.keys(paramsObject).length > 0
        ? new HttpParams({ fromObject: paramsObject })
        : null;
    return this.httpClient.get(
      `${this.intercomRoute}/partner-escalation/export`,
      {
        params: params,
        responseType: 'blob',
      }
    );
  }
}
