<form [formGroup]="form">
  <div class="mb-3">
    <label for="wabaId" class="form-label">WABA ID</label>
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        id="wabaId"
        formControlName="wabaId"
        [class.is-invalid]="
          form.get('wabaId').invalid && form.get('wabaId').touched
        "
      />
      <button
        class="btn btn-primary"
        type="button"
        [disabled]="form.get('wabaId').invalid"
        (click)="getWabaPrimaryFundingId()"
      >
        Check
      </button>
    </div>
    <div
      class="invalid-feedback"
      *ngIf="form.get('wabaId')['errors']?.['required'] && form.get('wabaId').touched"
    >
      WABA ID is required
    </div>
  </div>
  <div
    class="alert alert-success d-flex align-items-center justify-content-between"
    *ngIf="state.paymentMethodAttached && !state.error"
  >
    <span>Payment method exists. Currency: {{ state.wabaCurrency }}</span>
    <button class="btn btn-danger btn-nowrap" (click)="revokeCreditLine()">
      Revoke credit line
    </button>
  </div>

  <div class="alert alert-danger" *ngIf="state.error">
    <div
      class="d-flex flex-column flex-md-row gap-2 align-items-md-center justify-content-between"
    >
      <span class="text-break mb-2 mb-md-0">{{ state.errorMessage }}</span>
      <div
        class="d-flex align-items-center gap-2 flex-shrink-0"
        *ngIf="
          state.errorMessage === 'Credit line is revoked.' ||
          state.errorMessage === 'No funding ID found for the WABA.'
        "
      >
        <select
          class="form-select form-select-sm"
          formControlName="selectedCurrency"
        >
          <option *ngFor="let currency of currencies" [value]="currency">
            {{ currency }}
          </option>
        </select>
        <button
          class="btn btn-primary btn-nowrap"
          (click)="attachPaymentMethod()"
          [disabled]="form.invalid"
        >
          Attach payment method
        </button>
      </div>
    </div>
  </div>
</form>
