import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  GetWabaWebhookOptions,
  WabaWebhookListOutModel,
} from '../domains/models/waba-webhook.model';
import { HelperService } from './helper/helper.service';

@Injectable({
  providedIn: 'root',
})
export class WabaHelperService {
  wabaHelperRoute: string = `${environment.tppCsToolAPI.url}/v1/waba`;

  constructor(private http: HttpClient, private helperService: HelperService) {}
  assignWabaToMPS(body: {}): Observable<any> {
    return this.http.post<any>(
      `${this.wabaHelperRoute}/assign-waba-to-mps`,
      body
    );
  }

  getWabaWebhookLogs(
    options: GetWabaWebhookOptions
  ): Observable<WabaWebhookListOutModel> {
    const { reset, offset, limit } = options;
    const paramsObject = this.helperService.filterUndefinedProperties({
      reset: reset,
      offset: offset,
      limit: limit,
    });
    const params =
      Object.keys(paramsObject).length > 0
        ? new HttpParams({ fromObject: paramsObject })
        : null;
    return this.http.get<WabaWebhookListOutModel>(
      `${this.wabaHelperRoute}/webhook`,
      { params }
    );
  }
}
