import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-entity-json',
  templateUrl: './entity-json.component.html',
  styleUrls: ['./entity-json.component.scss'],
})
export class EntityJsonComponent {
  @Input() entityName: string;
  @Input() entityJson: any;

  constructor(private modalService: NgbModal) {}

  open(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
    });
  }
}
