import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, forkJoin } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
} from 'rxjs/operators';
import { AuthUserModel } from 'src/app/core/domains/models/auth-user.model';
import {
  GetWabaUserManagementAuditOptions,
  WabaUserManagementAuditCreateModel,
  WabaUserManagementAuditUpdateModel,
} from 'src/app/core/domains/models/waba-user-management-audit.model';
import {
  BusinessUser,
  BusinessUserListModel,
  WabaListModel,
  WabaUsersModel,
} from 'src/app/core/domains/models/waba-users.model';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { HubService } from 'src/app/core/services/hub.service';
import { SharedDataService } from 'src/app/core/services/shared-data/shared-data.service';
import { TppMetaService } from 'src/app/core/services/tpp-meta.service';
import { WabaUserManagementAuditService } from 'src/app/core/services/waba-user-management-audit.service';

const ERROR_MESSAGE =
  'An unexpected error occurred. Please report the issue in #cs-tool or create a ticket.';
const DEBOUNCE_TIME = 300;

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss'],
})
export class ManageUsersComponent implements OnInit, OnDestroy {
  @Input() wabaId: string;
  @Input() wabaStatus: string;
  form: FormGroup;
  wabaForm: FormGroup;
  private destroy$ = new Subject<void>();
  loading = true;
  errorLoadingMessage = '';
  errorLoading = false;
  wabaUsers: WabaListModel = {};
  partialAccessUsers: WabaUsersModel[] = [];
  fullAccessUsers: WabaUsersModel[] = [];
  businessUsers: BusinessUserListModel = {};
  selectedTasks: string[] = [];
  isFullControlChecked = false;
  wabaKnown: boolean = false;
  user: AuthUserModel = {};

  partialAccess = [
    {
      id: 'VIEW_TEMPLATES',
      title: 'Message templates (view only)',
      description: 'View and use message templates.',
      enabled: false,
      disabled: false,
    },
    {
      id: 'MANAGE_TEMPLATES',
      title: 'Message templates (view and manage)',
      description: 'Create, edit, and delete message templates.',
      enabled: false,
      disabled: false,
    },
    {
      id: 'VIEW_PHONE_ASSETS',
      title: 'Phone Numbers (view only)',
      description: 'View phone numbers and profiles.',
      enabled: false,
      disabled: false,
    },
    {
      id: 'MANAGE_PHONE_ASSETS',
      title: 'Phone Numbers (view and manage)',
      description: 'Add and delete phone numbers. Manage their settings.',
      enabled: false,
      disabled: false,
    },
    {
      id: 'DEVELOP',
      title: 'Manage phone numbers and templates',
      description: 'Create templates, manage numbers, and send messages.',
      enabled: false,
      disabled: false,
    },
  ];

  fullControl = [
    {
      id: 'MANAGE',
      title: 'Manage WhatsApp accounts',
      description: 'Manage all settings, users, and payments.',
      enabled: false,
      disabled: false,
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private tppMetaService: TppMetaService,
    private sharedDataService: SharedDataService,
    private wabaUserManagementAuditService: WabaUserManagementAuditService,
    private hubService: HubService
  ) {}

  ngOnInit(): void {
    this.wabaKnown = !!this.wabaId;
    this.generateForm();
    if (!this.wabaKnown) {
      this.generateWabaForm();
      return;
    }
    this.loadWabaData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(): void {
    this.helperService.dismissModal();
  }

  setWabaId() {
    this.wabaId = this.wabaForm.get('wabaId').value;
    this.loadWabaData();
  }

  private loadWabaData(): void {
    this.setDevelopDefault();
    this.loadData();
    this.getUser();
  }

  toggleFullControl(isChecked: boolean): void {
    this.updateAccessState(this.partialAccess, isChecked, isChecked);
    this.updateAccessState(this.fullControl, isChecked, isChecked);
    this.selectedTasks = isChecked ? ['MANAGE'] : [];
    this.isFullControlChecked = isChecked;
  }

  toggleDevelop(isChecked: boolean): void {
    if (isChecked) {
      this.partialAccess.forEach((access) => {
        access.enabled = true;
        access.disabled = access.id !== 'DEVELOP';
      });
      this.updateAccessState(this.fullControl, false, true);
      this.selectedTasks = ['DEVELOP'];
    } else {
      this.partialAccess.forEach((access) => {
        access.enabled = false;
        access.disabled = false;
      });
      this.selectedTasks = [];
    }
  }

  get allUsers(): WabaUsersModel[] {
    return [...this.fullAccessUsers, ...this.partialAccessUsers];
  }

  togglePartialAccess(id: string, isChecked: boolean): void {
    const relatedMapping: Record<string, string> = {
      MANAGE_PHONE_ASSETS: 'VIEW_PHONE_ASSETS',
      MANAGE_TEMPLATES: 'VIEW_TEMPLATES',
    };

    if (relatedMapping[id]) {
      const relatedAccess = this.partialAccess.find(
        (access) => access.id === relatedMapping[id]
      );
      if (relatedAccess)
        Object.assign(relatedAccess, {
          enabled: isChecked,
          disabled: isChecked,
        });
    }

    this.selectedTasks = isChecked
      ? [...new Set([...this.selectedTasks, id])]
      : this.selectedTasks.filter((task) => task !== id);
  }

  private updateAccessState(
    accessList: any[],
    enabled: boolean,
    disabled: boolean
  ): void {
    accessList.forEach((access) => {
      access.enabled = enabled;
      access.disabled = disabled;
    });
  }

  search = (text$: Observable<string>): Observable<BusinessUser[]> =>
    text$.pipe(
      debounceTime(DEBOUNCE_TIME),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.businessUsers?.data.filter((user) =>
              user.name.toLowerCase().includes(term.toLowerCase())
            )
      )
    );

  setFormValue(event: NgbTypeaheadSelectItemEvent<BusinessUser>): void {
    this.form.patchValue({ user: event.item });
  }

  resultFormatter = (user: BusinessUser): string => user.name;
  inputFormatter = (user: BusinessUser): string => user.name;

  validateSelection(): void {
    const selectedValue = this.form.get('user')?.value;
    const validUser = this.businessUsers?.data?.find(
      (user) => user === selectedValue
    );
    if (!validUser) {
      this.form.get('user')?.setErrors({ invalidSelection: true });
    } else {
      this.form.get('user')?.setErrors(null);
    }
  }

  canAddUser(): boolean {
    return (
      !this.form.valid || this.helperService.isObjectEmpty(this.selectedTasks)
    );
  }

  addUserToWaba(): void {
    this.helperService.showSpinner();
    const user = this.form.controls['user'].value as BusinessUser;
    if (this.selectedTasks.includes('MANAGE_TEMPLATES')) {
      this.selectedTasks = this.selectedTasks.filter(
        (task) => task !== 'VIEW_TEMPLATES'
      );
    }
    if (this.selectedTasks.includes('MANAGE_PHONE_ASSETS')) {
      this.selectedTasks = this.selectedTasks.filter(
        (task) => task !== 'VIEW_PHONE_ASSETS'
      );
    }
    this.tppMetaService
      .assignUserToWabaWithSpecificTask(
        this.wabaId,
        user.id,
        '[' + this.selectedTasks.map((task) => `"${task}"`).join(',') + ']'
      )
      .subscribe({
        error: (err) =>
          this.helperService.openErrorModal('Fail to assign task', err),
        complete: () => {
          this.helperService.showSuccessToastr('User added successfully!');
          this.loadData();
          this.manageAudit(user.id);
        },
      });
  }

  manageAudit(user_fb_id: string, deleteUser: boolean = false): void {
    const getWabaUserManagementAuditOptions: GetWabaUserManagementAuditOptions =
      {
        reset: true,
        user_fb_id: user_fb_id,
        waba_id: this.wabaId,
        valid: true,
      };
    this.wabaUserManagementAuditService
      .getWabaManagementAudit(getWabaUserManagementAuditOptions)
      .subscribe({
        next: (res) => {
          if (res.total_count == 0 && !deleteUser) {
            this.createWabaManagementAudit(user_fb_id);
          } else if (res.total_count != 0) {
            this.updateWabaManagementAudit(
              res.data[0].id,
              user_fb_id,
              deleteUser
            );
          }
        },
        error: (err) => {
          throw new Error(`Fail to fetch audit: ${err}`);
        },
      });
  }

  updateWabaManagementAudit(
    auditId: string,
    user_fb_id: string,
    deleteUser
  ): void {
    const wabaUserManagementAuditUpdateModel: WabaUserManagementAuditUpdateModel =
      {
        valid: false,
        modified_by: this.user.user_id,
      };
    this.wabaUserManagementAuditService
      .updateWabaManagementAudit(auditId, wabaUserManagementAuditUpdateModel)
      .subscribe({
        error: (err) => {
          throw new Error(`Fail to update audit: ${err}`);
        },
        complete: () => {
          if (!deleteUser) {
            this.createWabaManagementAudit(user_fb_id);
          }
        },
      });
  }

  createWabaManagementAudit(user_fb_id: string): void {
    const wabaManagementAuditCreate: WabaUserManagementAuditCreateModel = {
      user_id: this.user.user_id,
      user_name: localStorage.getItem('hub_username'),
      user_fb_id: user_fb_id,
      waba_id: this.wabaId,
      tasks: this.selectedTasks,
    };
    this.wabaUserManagementAuditService
      .createWabaManagementAudit(wabaManagementAuditCreate)
      .subscribe({
        error: (err) => {
          throw new Error(`Fail to store audit: ${err}`);
        },
      });
  }

  deleteUser(userId: string): void {
    this.helperService.showSpinner();
    this.tppMetaService.deleteUserFromWaba(this.wabaId, userId).subscribe({
      next: () => {
        this.manageAudit(userId, true);
        this.helperService.showSuccessToastr('User deleted successfully!');
        this.loadData();
      },
      error: (err) =>
        this.helperService.openErrorModal('Failed to delete user', err),
    });
  }

  assignToMe(): void {
    const hubUsername = localStorage.getItem('hub_username');
    const user = this.businessUsers?.data?.find(
      (user) => user.name === hubUsername
    );
    if (!user && !this.errorLoading) {
      this.helperService.showErrorToastr(
        'Fail to find user in the business manager. Please select the user manually.'
      );
      return;
    }
    this.form.patchValue({
      user: user,
    });
    this.validateSelection();
  }

  private setDevelopDefault() {
    const developAccess = this.partialAccess.find(
      (access) => access.id === 'DEVELOP'
    );
    if (developAccess) {
      developAccess.enabled = true;
      this.selectedTasks.push(developAccess.id);
      this.toggleDevelop(true);
    }
  }

  private loadData(): void {
    this.loading = true;
    this.errorLoading = false;
    this.helperService.showSpinner();

    const requests: Record<string, Observable<any>> = {
      wabaUsers: this.tppMetaService.getWabaUsers(this.wabaId),
      businessUsers: this.tppMetaService.getBusinessUsers(),
    };

    if (!this.wabaKnown) {
      requests['wabaStatus'] = this.hubService.getChannels('waba', this.wabaId);
    }

    forkJoin(requests).subscribe({
      next: (results: any) => {
        this.wabaUsers = results.wabaUsers;
        this.businessUsers = results.businessUsers;
        if (!this.wabaKnown) {
          this.wabaStatus =
            results?.wabaStatus?.partner_channels[0]?.waba_account?.status;
        }
        this.fullAccessUsers = this.wabaUsers.data.filter((user) =>
          user.tasks.includes('MANAGE')
        );
        this.partialAccessUsers = this.wabaUsers.data.filter(
          (user) => !user.tasks.includes('MANAGE')
        );
        this.loading = false;
        this.helperService.hideSpinner(false);
      },
      error: (error) => {
        if (
          error?.error?.error?.code === 100 &&
          error?.error?.error?.error_subcode === 33
        ) {
          this.errorLoadingMessage =
            error?.error?.error?.message ?? ERROR_MESSAGE;
        } else {
          this.errorLoadingMessage = ERROR_MESSAGE;
        }
        this.errorLoading = true;
        this.helperService.hideSpinner(false);
        this.loading = false;
      },
    });
  }

  private getUser(): void {
    this.sharedDataService.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: AuthUserModel) => {
        this.user = user;
      });
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({
      user: ['', Validators.required],
    });
  }

  private generateWabaForm(): void {
    this.wabaForm = this.formBuilder.group({
      wabaId: ['', [Validators.required]],
    });
  }
}
