import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

const params = new HttpParams().set('reset', 'true');

@Injectable({
  providedIn: 'root',
})
export class InstatusService {
  instatusRoute: string = `${environment.tppCsToolAPI.url}/instatus`;

  constructor(private httpClient: HttpClient) {}

  getComponents(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.instatusRoute}/v1/${environment.instatusPage}/components`,
      {
        params,
      }
    );
  }

  getTemplates(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.instatusRoute}/v1/${environment.instatusPage}/templates`,
      {
        params,
      }
    );
  }

  getIncident(incidentId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.instatusRoute}/v1/${environment.instatusPage}/incidents/${incidentId}`,
      {
        params,
      }
    );
  }

  createInstatus(body: {}): Observable<any> {
    return this.httpClient.post(
      `${this.instatusRoute}/v1/${environment.instatusPage}/incidents`,
      body
    );
  }

  addInstatusUpdate(incidentId: string, body: {}): Observable<any> {
    return this.httpClient.post(
      `${this.instatusRoute}/v1/${environment.instatusPage}/incidents/${incidentId}/incident-updates`,
      body
    );
  }

  editInstatusUpdate(
    incidentId: string,
    updateId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.put(
      `${this.instatusRoute}/v1/${environment.instatusPage}/incidents/${incidentId}/incident-updates/${updateId}`,
      body
    );
  }

  updateIncident(incidentId: string, body: {}): Observable<any> {
    return this.httpClient.put(
      `${this.instatusRoute}/v1/${environment.instatusPage}/incidents/${incidentId}`,
      body
    );
  }

  deleteIncident(incidentId: string): Observable<any> {
    return this.httpClient.delete<any>(
      `${this.instatusRoute}/v1/${environment.instatusPage}/incidents/${incidentId}`
    );
  }

  deleteIncidentUpdate(incidentId: string, updateId: string): Observable<any> {
    return this.httpClient.delete<any>(
      `${this.instatusRoute}/v1/${environment.instatusPage}/incidents/${incidentId}/incident-updates${updateId}`
    );
  }
}
