import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { WabaPhoneDetails } from '../domains/models/waba-phone-details.module';
import {
  BusinessUserListModel,
  WabaListModel,
} from '../domains/models/waba-users.model';
import { CatalogAssignedUsers } from '../domains/models/catalog-assigned-users.module';

const params = new HttpParams().set('reset', 'true').set('locale', 'en');
const businessId = '734443296905665';

@Injectable({
  providedIn: 'root',
})
export class TppMetaService {
  tppMetaRoute: string = `${environment.tppCsToolAPI.url}/meta`;

  constructor(private httpClient: HttpClient) {}

  getSubscribedApps(wabaId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.tppMetaRoute}/v1/app/main/${wabaId}/subscribed_apps`,
      { params }
    );
  }

  getPhoneNumbers(wabaId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.tppMetaRoute}/v1/app/main/${wabaId}/phone_numbers`,
      { params }
    );
  }

  getWabaInfo(wabaId: string): Observable<any> {
    const params = new HttpParams()
      .set(
        'fields',
        'id,name,currency,timezone_id,message_template_namespace,primary_funding_id'
      )
      .set('locale', 'en');
    return this.httpClient.get<any>(
      `${this.tppMetaRoute}/v1/app/main/${wabaId}`,
      { params }
    );
  }

  getCatalogAssignedUsers(catalogId: string): Observable<CatalogAssignedUsers> {
    const params = new HttpParams()
      .set('business', `${businessId}`)
      .set('locale', 'en')
      .set('reset', 'true');
    return this.httpClient.get<CatalogAssignedUsers>(
      `${this.tppMetaRoute}/v1/app/main/v22.0/${catalogId}/assigned_users`,
      { params }
    );
  }

  getBusinessUsers(): Observable<BusinessUserListModel> {
    const params = new HttpParams().set('limit', '200').set('locale', 'en');
    return this.httpClient.get<BusinessUserListModel>(
      `${this.tppMetaRoute}/v1/app/main/${businessId}/business_users`,
      { params }
    );
  }

  getWabaUsers(wabaId): Observable<WabaListModel> {
    const params = new HttpParams()
      .set('business', `${businessId}`)
      .set('locale', 'en')
      .set('reset', 'true');
    return this.httpClient.get<WabaListModel>(
      `${this.tppMetaRoute}/v1/app/main/${wabaId}/assigned_users`,
      { params }
    );
  }

  getCloudAPIPhoneInfo(phoneNumberId: string): Observable<WabaPhoneDetails> {
    return this.httpClient.get<WabaPhoneDetails>(
      `${this.tppMetaRoute}/v1/app/cloud/${phoneNumberId}`,
      { params }
    );
  }

  getOnPremPhoneInfo(phoneNumberId: string): Observable<WabaPhoneDetails> {
    return this.httpClient.get<WabaPhoneDetails>(
      `${this.tppMetaRoute}/v1/app/main/${phoneNumberId}`,
      { params }
    );
  }

  getCloudAPIThroughput(phoneNumberId: string): Observable<any> {
    const params = new HttpParams()
      .set('fields', 'throughput')
      .set('locale', 'en');
    return this.httpClient.get<any>(
      `${this.tppMetaRoute}/v1/app/cloud/${phoneNumberId}`,
      { params }
    );
  }

  getWabaPrimaryFundingId(wabaId: string): Observable<any> {
    const params = new HttpParams()
      .set('fields', 'primary_funding_id, currency')
      .set('reset', 'true')
      .set('locale', 'en');
    return this.httpClient.get(`${this.tppMetaRoute}/v1/app/main/${wabaId}`, {
      params,
    });
  }

  getCreditLineInformation(primaryFundingId: string): Observable<any> {
    const params = new HttpParams()
      .set(
        'fields',
        'is_access_revoked,credit_type,id,is_automated_experience,legal_entity_name,liable_biz_name,owner_business,owner_business_name,partition_from,send_bill_to_biz_name,receiving_credit_allocation_config'
      )
      .set('reset', 'true')
      .set('locale', 'en');
    return this.httpClient.get(
      `${this.tppMetaRoute}/v1/app/main/${primaryFundingId}`,
      {
        params,
      }
    );
  }

  getOwningCredentials(partitionTypeId: string): Observable<any> {
    const params = new HttpParams()
      .set('fields', 'owning_credential')
      .set('reset', 'true')
      .set('locale', 'en');
    return this.httpClient.get(
      `${this.tppMetaRoute}/v1/app/main/${partitionTypeId}`,
      {
        params,
      }
    );
  }

  getCustomerBusinessPortfolioId(wabaId: string): Observable<any> {
    const params = new HttpParams()
      .set('fields', 'owner_business_info')
      .set('reset', 'true')
      .set('locale', 'en');
    return this.httpClient.get(`${this.tppMetaRoute}/v1/app/main/${wabaId}`, {
      params,
    });
  }

  getAllocationId(businessId: string): Observable<any> {
    const params = new HttpParams()
      .set('receiving_business_id', businessId)
      .set('fields', 'id,receiving_business')
      .set('reset', 'true')
      .set('locale', 'en');
    return this.httpClient.get(
      `${this.tppMetaRoute}/v1/app/main/3214250008690288/owning_credit_allocation_configs`,
      { params }
    );
  }

  verifyCreditLineDeleted(allocationId: string): Observable<any> {
    const params = new HttpParams()
      .set('fields', 'receiving_business, request_status')
      .set('locale', 'en');
    return this.httpClient.get(
      `${this.tppMetaRoute}/v1/app/main/${allocationId}`,
      { params }
    );
  }

  disableBtnAnalytics(templateId: string, category: string): Observable<any> {
    const params = new HttpParams()
      .set('cta_url_link_tracking_opted_out', 'true')
      .set('category', category);
    return this.httpClient.post(
      `${this.tppMetaRoute}/v1/app/main/${templateId}`,
      {},
      { params }
    );
  }

  shareCreditLineWithCustomerBusiness(
    creditId: string,
    businessPortfolioId: string
  ): Observable<any> {
    const params = new HttpParams()
      .set('receiving_business_id', businessPortfolioId)
      .set('locale', 'en');
    return this.httpClient.post(
      `${this.tppMetaRoute}/v1/app/main/${creditId}/whatsapp_credit_sharing`,
      {},
      {
        params,
      }
    );
  }

  setPaymentMethod(
    creditId: string,
    wabaId: string,
    wabaCurrency: string
  ): Observable<any> {
    const params = new HttpParams()
      .set('waba_id', wabaId)
      .set('waba_currency', wabaCurrency)
      .set('locale', 'en');
    return this.httpClient.post(
      `${this.tppMetaRoute}/v1/app/main/${creditId}/whatsapp_credit_sharing_and_attach`,
      {},
      {
        params,
      }
    );
  }

  assignUserToWaba(wabaId: string, userId: string): Observable<any> {
    const params = new HttpParams()
      .set('user', userId)
      .set('tasks', '["MANAGE"]')
      .set('locale', 'en');
    return this.httpClient.post<any>(
      `${this.tppMetaRoute}/v1/app/main/${wabaId}/assigned_users`,
      {},
      { params }
    );
  }

  assignUserToCatalog(
    catalogId: string,
    userId: string,
    tasks: string
  ): Observable<any> {
    const params = new HttpParams()
      .set('user', userId)
      .set('tasks', tasks)
      .set('locale', 'en');
    return this.httpClient.post<any>(
      `${this.tppMetaRoute}/v1/app/main/${catalogId}/assigned_users`,
      {},
      { params }
    );
  }

  assignUserToWabaWithSpecificTask(
    wabaId: string,
    userId: string,
    tasks: string
  ): Observable<any> {
    const params = new HttpParams()
      .set('user', userId)
      .set('tasks', tasks)
      .set('locale', 'en');
    return this.httpClient.post<any>(
      `${this.tppMetaRoute}/v1/app/main/${wabaId}/assigned_users`,
      {},
      { params }
    );
  }

  addSubscribeApp(wabaId): Observable<any> {
    return this.httpClient.post<any>(
      `${this.tppMetaRoute}/v1/app/cloud/${wabaId}/subscribed_apps`,
      {},
      { params }
    );
  }

  registerCloudApiDisplayName(
    phoneNumberId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post(
      `${this.tppMetaRoute}/v1/app/main/${phoneNumberId}/register`,
      body
    );
  }

  triggerCloudOtp(phoneNumberId: string, body: {}): Observable<any> {
    return this.httpClient.post<any>(
      `${this.tppMetaRoute}/v1/app/cloud/${phoneNumberId}/request_code`,
      body
    );
  }

  verifyCloudOtp(phoneNumberId: string, body: {}): Observable<any> {
    return this.httpClient.post<any>(
      `${this.tppMetaRoute}/v1/app/cloud/${phoneNumberId}/verify_code`,
      body
    );
  }

  triggerOnPremOtp(phoneNumberId: string, body: {}): Observable<any> {
    return this.httpClient.post<any>(
      `${this.tppMetaRoute}/v1/app/main/${phoneNumberId}/request_code`,
      body
    );
  }

  verifyOnPremOtp(phoneNumberId: string, body: {}): Observable<any> {
    return this.httpClient.post<any>(
      `${this.tppMetaRoute}/v1/app/main/${phoneNumberId}/verify_code`,
      body
    );
  }

  migrateTemplates(
    destinationWabaId: string,
    sourceWabaId: string
  ): Observable<any> {
    const params = new HttpParams()
      .set('source_waba_id', sourceWabaId)
      .set('locale', 'en');
    return this.httpClient.post(
      `${this.tppMetaRoute}/v1/app/main/${destinationWabaId}/migrate_message_templates`,
      {},
      { params }
    );
  }

  deleteCreditLine(allocationId: string): Observable<any> {
    return this.httpClient.delete(
      `${this.tppMetaRoute}/v1/app/main/${allocationId}`
    );
  }

  deleteUserFromWaba(wabaId: string, userId: string): Observable<any> {
    const params = new HttpParams().set('user', userId).set('locale', 'en');
    return this.httpClient.delete<any>(
      `${this.tppMetaRoute}/v1/app/main/${wabaId}/assigned_users`,
      { params }
    );
  }

  deleteUserFromCatalog(catalogId: string, userId: string): Observable<any> {
    const params = new HttpParams().set('user', userId).set('locale', 'en');
    return this.httpClient.delete<any>(
      `${this.tppMetaRoute}/v1/app/main/${catalogId}/assigned_users`,
      { params }
    );
  }
}
