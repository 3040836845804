import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { BusinessCompliance } from '../domains/models/business-compliance.module';

const params = new HttpParams().set('reset', 'true');

@Injectable({
  providedIn: 'root',
})
export class OnPremiseService {
  onPremiseRoute: string = `${environment.tppCsToolAPI.url}/on_premise`;

  constructor(private httpClient: HttpClient) {}

  // # GET

  getOnPremiseBusinessCompliance(
    partnerId: string,
    appId: string,
    reset: boolean
  ): Observable<BusinessCompliance> {
    const fromObject: { [index: string]: string } = { reset: reset.toString() };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<BusinessCompliance>(
      `${this.onPremiseRoute}/${partnerId}/${appId}/v1/settings/business/compliance_info`,
      { params }
    );
  }

  getOnPremiseWebhook(
    partnerId: string,
    appId: string,
    reset: boolean = false
  ): Observable<any> {
    const fromObject: { [index: string]: string } = { reset: reset.toString() };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${this.onPremiseRoute}/${partnerId}/${appId}/v1/configs/webhook`,
      { params }
    );
  }

  // # POST

  updateOnPremiseBusinessCompliance(
    partnerId: string,
    appId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post(
      `${this.onPremiseRoute}/${partnerId}/${appId}/v1/settings/business/compliance_info`,
      body
    );
  }

  updateOnPremiseWebhook(
    partnerId: string,
    appId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post(
      `${this.onPremiseRoute}/${partnerId}/${appId}/v1/configs/webhook`,
      body
    );
  }

  sendOnPremiseMessage(
    partnerId: string,
    appId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post(
      `${this.onPremiseRoute}/${partnerId}/${appId}/v1/messages`,
      body
    );
  }

  // # PATCH

  // # DELETE
}
