<div class="modal-header">
    <div class="d-inline-flex align-items-center">
        <h4 class="modal-title me-2">WABA User Management</h4>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="wabaForm" class="pb-3" *ngIf="!wabaKnown">
        <label>Waba External ID</label>
        <div class="input-group">
            <input type="text" class="form-control" formControlName="wabaId">
            <div class="input-group-prepend">
                <button class="btn btn-primary" type="submit" (click)="setWabaId()">Check</button>
            </div>
        </div>
    </form>
    <div *ngIf="loading && wabaKnown" class="text-center">
        <span>Loading Data</span>
    </div>
    <div *ngIf="!loading">
        <h5 class="fb pb-2">
            {{ wabaUsers?.data?.length > 0 ? (wabaUsers?.data?.length + ' people are assigned ') :
            'No users assigned '}} to this WhatsApp account</h5>
        <div *ngFor="let user of allUsers">
            <div class="d-flex align-items-center justify-content-between border-bottom py-2">
                <div>
                    <p class="mb-0">{{ user.name }}</p>
                    <small class="text-muted">{{ user.tasks }}</small>
                </div>
                <button class="btn btn-outline-danger btn-sm"
                    *ngIf="!['112511530302197', '125288958872789'].includes(user.id)" (click)="deleteUser(user.id)">
                    Delete
                </button>
            </div>
        </div>

        <div class="py-3">
            <h5 class="fb pb-2">Assign or update user tasks</h5>
            <form [formGroup]="form" class="row">
                <div class="col-12">
                    <label>User</label>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" [ngbTypeahead]="search"
                            (selectItem)="setFormValue($event)" (blur)="validateSelection()"
                            [resultFormatter]="resultFormatter" [inputFormatter]="inputFormatter"
                            formControlName="user" />
                        <div class="input-group-append">
                            <button class="btn btn-outline-primary" type="button" (click)="assignToMe()">Assign to
                                me</button>
                        </div>
                    </div>
                    <div *ngIf="form.get('user')?.hasError('invalidSelection') && form.get('user')?.touched"
                        class="text-danger">
                        Please select a valid user from the given options.
                    </div>
                </div>
            </form>
            <div class="py-2">
                <h5 class="pb-1">Tasks</h5>
                <div>
                    <div class="form-check form-switch" *ngFor="let access of partialAccess">
                        <input class="form-check-input" type="checkbox" id="{{ access.id }}"
                            [(ngModel)]="access.enabled" [disabled]="access.disabled"
                            (change)="access.id === 'DEVELOP' ? toggleDevelop(access.enabled) : togglePartialAccess(access.id, access.enabled)" />
                        <label class="form-check-label" [for]="access.id">
                            <strong>{{ access.title }}</strong><br />
                            <small class="text-muted">{{ access.description }}</small>
                        </label>
                    </div>
                </div>
                <div>
                    <div class="form-check form-switch mb-3" *ngFor="let access of fullControl">
                        <input class="form-check-input" type="checkbox" id="{{ access.id }}"
                            [(ngModel)]="access.enabled" (change)="toggleFullControl(access.enabled)" />
                        <label class="form-check-label" [for]="access.id">
                            <strong>{{ access.title }}</strong><br />
                            <small class="text-muted">{{ access.description }}</small>
                        </label>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-primary btn-sm" [disabled]="canAddUser()"
                    (click)="addUserToWaba()">Assign</button>
            </div>
        </div>
    </div>
    <div *ngIf="errorLoading" class="text-center">
        <span class="text-danger">{{errorLoadingMessage}}</span>
    </div>
</div>