import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { WebhookService } from 'src/app/core/services/webhook.service';

@Component({
  selector: 'app-ssl-check',
  templateUrl: './ssl-check.component.html',
})
export class SslCheckComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private webhookService: WebhookService
  ) {}

  form: any;
  response: any;

  ngOnInit(): void {
    this.generateForm();
  }

  close(): void {
    this.helperService.dismissModal();
  }

  confirm(): void {
    this.helperService.showSpinner();
    let body: {};
    try {
      body = {
        hostname: new URL(this.form.controls.hostname.value).hostname,
      };
    } catch (error) {
      let errorMessage;
      if (error instanceof TypeError) {
        errorMessage =
          'The entered URL is invalid. Please provide a valid URL.';
      } else {
        errorMessage = 'An unexpected error occurred. Please try again.';
      }
      this.helperService.showErrorToastr(errorMessage);
    }
    this.webhookService.sslCheck(body).subscribe({
      next: (res) => {
        this.response = res;
        this.helperService.hideSpinner(false);
      },
      error: (error) =>
        this.helperService.openErrorModal('Fail to check SSL Cert', error),
    });
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({
      hostname: new FormControl('', [Validators.required]),
    });
  }
}
