<div class="container-fluid p-0">
    <div class="wizard-card shadow-lg">
        <div class="wizard-header position-relative">
            <button type="button" class="btn-close btn-close-white position-absolute top-0 end-0 m-2"
                (click)="close()"></button>
            <h3 class="mb-0">WABA Test</h3>
        </div>
        <div class="wizard-content p-4">
            <div>
                <h5 class="mb-3">Please select which scenario you want to test</h5>
                <div class="d-flex flex-column mb-3">
                    <h6 class="fb">Scenario 1 - Single WABA with one number</h6>
                    <ol class="list-group list-group-numbered">
                        <li class="list-group-item">Send a message to the business (to be done from a phone).</li>
                        <li class="list-group-item">Check if the webhook is received.</li>
                        <li class="list-group-item">Reply from the business to the WhatsApp user.</li>
                        <li class="list-group-item">Check if `sent` && `delivered` webhook is received.</li>
                    </ol>
                </div>
                <div class="d-flex flex-column mb-3">
                    <h6 class="fb">Scenario 2 - Single WABA with two numbers</h6>
                    <ol class="list-group list-group-numbered">
                        <li class="list-group-item">Set a per-WABA webhook.</li>
                        <li class="list-group-item">Send messages to two different businesses (to be done from a phone).
                        </li>
                        <li class="list-group-item">Verify that both messages arrive at the same webhook.</li>
                        <li class="list-group-item">Reply from one business to the WhatsApp user.</li>
                        <li class="list-group-item">Set webhook per number (for both numbers).</li>
                        <li class="list-group-item">Send messages to two different businesses (to be done from a phone).
                        </li>
                        <li class="list-group-item">Check if the webhook is received (per webhook).</li>
                    </ol>
                </div>
                <select (change)="onScenarioSelected($event)" class="form-select">
                    <option value="">-- Select a scenario --</option>
                    <option value="scenario1">Scenario 1</option>
                    <option value="scenario2" disabled>Scenario 2</option>
                </select>
            </div>
        </div>
        <div class="wizard-footer px-4 py-3 d-flex align-items-center">
            <button class="btn btn-outline-secondary" (click)="close()">Close</button>
        </div>
    </div>
</div>