import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

const params = new HttpParams().set('reset', 'true');

@Injectable({
  providedIn: 'root',
})
export class TPPAuthXService {
  authXRoute: string = `${environment.tppCsToolAPI.url}/authx`;

  constructor(private httpClient: HttpClient) {}

  getAuthXUser(user_id: string): Observable<any> {
    const fromObject: { [index: string]: string } = { q: `user_id=${user_id}` };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(`${this.authXRoute}/v1/users`, {
      params,
    });
  }

  // getMultipleAuthXUser(user_ids: string[]): Observable<any> {
  //   const fromObject: { [index: string]: string } = {
  //     q: `user_id=(auth0|6675eda5219269ceed83cc8a OR auth0|669fc5134e69dcae3ce17b56)`,
  //   };
  //   const params = new HttpParams({ fromObject });
  //   return this.httpClient.get<any>(`${this.authXRoute}/v1/users`, {
  //     params,
  //   });
  // }
}
