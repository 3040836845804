<button type="button" class="btn-custom me-2" [ngbTooltip]="'View ' + entityName + ' JSON' "
    [ngStyle]="{ width: '40px' }" (click)="open(content)">
    <i class="bi bi-filetype-json"></i>
</button>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ entityName }}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <ngx-json-viewer [json]="entityJson"></ngx-json-viewer>
    </div>
</ng-template>