export enum MultiSearchTypeEnum {
  phoneNumber = 'Phone Number',
  appId = 'App ID',
  facebookBusinessId = 'Facebook Business ID',
  wabaName = 'WABA Name',
  wabaExternalId = 'WABA External ID',
  wabaId = 'WABA ID',
  partnerId = 'Partner ID',
  clientId = 'Client ID',
  clientName = 'Client Name',
  clientEmail = 'Client Email',
  projectName = 'Project Name',
  channelId = 'Channel ID',
  partnerEmail = 'Partner Email',
}

export enum SingleSearchTypeEnum {
  phoneNumber = 'Phone Number',
  appId = 'App ID',
  facebookBusinessId = 'Facebook Business ID',
  wabaName = 'WABA Name',
  wabaExternalId = 'WABA External ID',
  wabaId = 'WABA ID',
  partnerId = 'Partner ID',
  clientId = 'Client ID',
  clientName = 'Client Name',
  clientEmail = 'Client Email',
  projectName = 'Project Name',
  channelId = 'Channel ID',
  apiKey = 'Api Key',
  stackId = 'Stack ID',
  partnerEmail = 'Partner Email',
}

export enum PartnerSearchEnum {
  partnerId = 'Partner ID',
  partnerName = 'Partner Name',
}
