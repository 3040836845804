import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import {
  GetUserOptions,
  UserList,
  UserModel,
} from '../domains/models/user.model';
import { HelperService } from './helper/helper.service';

const params = new HttpParams().set('reset', 'true');

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userRoute: string = `${environment.tppCsToolAPI.url}/v1/users`;

  constructor(
    private httpClient: HttpClient,
    private helperService: HelperService
  ) {}

  getUserByUserId(userId: string): Observable<UserModel> {
    return this.httpClient.get<UserModel>(`${this.userRoute}/${userId}`, {
      params,
    });
  }

  getUserList(options: GetUserOptions = {}): Observable<UserList> {
    const { reset, id, offset, limit } = options;
    const paramsObject = this.helperService.filterUndefinedProperties({
      reset: reset,
      id: id,
      offset: offset,
      limit: limit,
    });
    const params =
      Object.keys(paramsObject).length > 0
        ? new HttpParams({ fromObject: paramsObject })
        : null;
    return this.httpClient.get<UserList>(`${this.userRoute}/`, { params });
  }

  createUser(user: UserModel): Observable<UserModel> {
    return this.httpClient.post<UserModel>(`${this.userRoute}/`, user);
  }

  updateUserById(userId: string, body: {}): Observable<UserModel> {
    return this.httpClient.put<UserModel>(`${this.userRoute}/${userId}`, 
      body,
    );
  }
}
