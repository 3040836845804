import { Component } from '@angular/core';
import { HelperService } from 'src/app/core/services/helper/helper.service';

@Component({
  selector: 'app-credit-line',
  templateUrl: './credit-line.component.html',
  styleUrls: ['./credit-line.component.scss'],
})
export class CreditLineComponent {
  activeTab: number = 1;

  constructor(private helperService: HelperService) {}

  close() {
    this.helperService.dismissModal();
  }
}
