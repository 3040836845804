import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CsToolApiService } from 'src/app/core/services/cs-tool-api/cs-tool-api.service';
import { HelperService } from 'src/app/core/services/helper/helper.service';

@Component({
  selector: 'app-migrate-phone-modal',
  templateUrl: './migrate-phone-modal.component.html',
  styleUrls: ['./migrate-phone-modal.component.scss'],
})
export class MigratePhoneModalComponent implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    protected activeModal: NgbActiveModal,
    protected helperService: HelperService,
    protected csToolApiService: CsToolApiService
  ) {}

  ngOnInit(): void {
    this.generateForm();
  }

  close() {
    this.activeModal.dismiss();
  }

  migratePhoneNumber(): void {
    // this.helperService.showSpinner();
    // this.csToolApiService.migratePhoneNumber({...this.form.value}).subscribe({
    //   next: (res) => {
    //     this.form.reset();
    //     this.helperService.hideSpinner();
    //     this.helperService.openErrorModal("Number migrated successfully", res);
    //     this.helperService.showSuccessToastr('Number migrated successfully.');
    //   },
    //   error: (err) => {
    //     this.helperService.openErrorModal("Migration Failed", err);
    //   }
    // });
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({
      phone_number: new FormControl('', [Validators.required]),
      cc: new FormControl('', [Validators.required]),
      waba_id: new FormControl('', [Validators.required]),
    });
  }
}
