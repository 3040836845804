import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment as env, environment } from 'src/environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ActionComponent } from './components/action/action.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CacheInterceptor } from './core/interceptor/cache.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { AttachTagModalComponent } from './components/action/modals/attach-tag-modal/attach-tag-modal.component';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { TokenInterceptorService } from './core/interceptor/token-interceptor.service';
import { SslCheckComponent } from './components/action/modals/ssl-check/ssl-check.component';
import { WabaPhoneNumbersComponent } from './components/action/modals/waba-phone-numbers/waba-phone-numbers.component';
import { ReactWrapperModule } from './react-wrapper/react-wrapper.module';
import { ReactViewComponent } from './components/react-view/react-view.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PageNotFoundComponent,
    ActionComponent,
    TopBarComponent,
    AttachTagModalComponent,
    SslCheckComponent,
    WabaPhoneNumbersComponent,
    ReactViewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    SharedModule,
    NgbModule,
    NgxJsonViewerModule,
    ReactWrapperModule,
    AuthModule.forRoot({
      ...environment.auth0,
      httpInterceptor: {
        allowedList: [
          `${env.tppCsToolAPI.url}/hub/*`,
          `${env.tppCsToolAPI.url}/v1/authorization/*`,
          `${env.tppCsToolAPI.url}/on_premise/*`,
          `${env.tppCsToolAPI.url}/cloud/*`,
          `${env.tppCsToolAPI.url}/insight/*`,
          `${env.tppCsToolAPI.url}/v1/jira/*`,
          `${env.tppCsToolAPI.url}/v1/sendgrid/*`,
          `${env.tppCsToolAPI.url}/v1/users/*`,
          `${env.tppCsToolAPI.url}/stack/*`,
          `${env.tppCsToolAPI.url}/v1/intercom/*`,
          `${env.tppCsToolAPI.url}/v1/databricks/*`,
          `${env.tppCsToolAPI.url}/plt/*`,
          `${env.tppCsToolAPI.url}/meta/*`,
          `${env.tppCsToolAPI.url}/pbs/*`,
          `${env.tppCsToolAPI.url}/cr/*`,
          `${env.tppCsToolAPI.url}/v1/webhook/*`,
          `${env.tppCsToolAPI.url}/v1/partner-extras/*`,
          `${env.tppCsToolAPI.url}/v1/partner-escalations/*`,
          `${env.tppCsToolAPI.url}/v1/wa-notification-groups/*`,
          `${env.tppCsToolAPI.url}/v1/known-issues/*`,
          `${env.tppCsToolAPI.url}/instatus/*`,
          `${env.tppCsToolAPI.url}/authx/*`,
        ],
      },
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      progressBar: true,
      toastClass: 'ngx-toastr position-fixed custom-toaster',
      tapToDismiss: false,
      closeButton: true,
      extendedTimeOut: 5000,
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled:
        (env.production || env.stage) && !window.origin.includes('localhost'),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false, // hide for now
        dialogOptions: {
          user: {
            name: localStorage.getItem('hub_username') ?? null,
          },
        },
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
