<div class="modal-header">
    <div class="d-inline-flex align-items-center">
        <h4 class="modal-title me-2">{{ modalDetails.title }}</h4>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="form-group" *ngFor="let input of modalDetails.inputs; let i = index">
            <label>{{ input.label }}</label>
            <input [type]="input.type" class="form-control" [formControlName]="'input'+i">
        </div>
    </form>
</div>
<div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-danger" (click)="close()">Close</button>
    <button type="button" class="btn btn-primary" [disabled]="!this.form.valid" (click)="confirm()">
        {{ modalDetails.confirmationText ?? 'Update' }}
    </button>
</div>