<div class="modal-content">
  <div class="modal-header">
    <h5 class="modal-title">Manage Credit Line</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="close()"
    ></button>
  </div>

  <div class="modal-body">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink>WABA</a>
        <ng-template ngbNavContent>
          <app-nav-waba></app-nav-waba>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Business Manager</a>
        <ng-template ngbNavContent>
          <app-nav-business-manager></app-nav-business-manager>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="bg-white p-4"></div>
  </div>

  <div class="modal-footer d-flex justify-content-start">
    <button type="button" class="btn btn-danger" (click)="close()">
      Close
    </button>
  </div>
</div>
