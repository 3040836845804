import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-receive-message-step',
  templateUrl: './receive-message-step.component.html',
})
export class ReceiveMessageStepComponent {
  @Input() phoneForm: FormGroup;
  @Output() sendMessage = new EventEmitter<void>();

  onSendMessage(): void {
    this.sendMessage.emit();
  }
}
