import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HelperService } from './helper/helper.service';
import { environment } from 'src/environments/environment';
import {
  GetWaNotificationGroupOptions,
  WANotificationGroupOut,
  WaNotificationGroupUpdateModel,
} from '../domains/models/wa-notification-group.model';

const params = new HttpParams().set('reset', 'true');

@Injectable({
  providedIn: 'root',
})
export class WANotificationGroupService {
  waNotificatioNGroupRoute: string = `${environment.tppCsToolAPI.url}/v1/wa-notification-groups`;

  constructor(
    private helperService: HelperService,
    private httpClient: HttpClient
  ) {}

  getWaNotificationGroup(groupId: string): Observable<WANotificationGroupOut> {
    return this.httpClient.get<WANotificationGroupOut>(
      `${this.waNotificatioNGroupRoute}/${groupId}`,
      {
        params,
      }
    );
  }

  getWaNotificationGroups(
    options: GetWaNotificationGroupOptions = {}
  ): Observable<WANotificationGroupOut[]> {
    const { reset, id, name } = options;
    const paramsObject = this.helperService.filterUndefinedProperties({
      reset: reset,
      id: id,
      name: name,
    });
    const params =
      Object.keys(paramsObject).length > 0
        ? new HttpParams({ fromObject: paramsObject })
        : null;
    return this.httpClient.get<WANotificationGroupOut[]>(
      `${this.waNotificatioNGroupRoute}/`,
      {
        params,
      }
    );
  }

  createWaNotificationGroup(
    waNotificationGroup: WaNotificationGroupUpdateModel
  ): Observable<WANotificationGroupOut> {
    return this.httpClient.post<WANotificationGroupOut>(
      `${this.waNotificatioNGroupRoute}/`,
      waNotificationGroup
    );
  }

  updateWaNotificationGroup(
    group_id: string,
    waNotificationGroup: WaNotificationGroupUpdateModel
  ): Observable<WANotificationGroupOut> {
    return this.httpClient.put<WANotificationGroupOut>(
      `${this.waNotificatioNGroupRoute}/${group_id}`,
      waNotificationGroup
    );
  }
}
