import { Component, OnInit } from '@angular/core';
import DemoComponent from 'src/react-components/DemoComponent';

@Component({
  selector: 'app-react-view',
  templateUrl: './react-view.component.html',
})
export class ReactViewComponent implements OnInit {
  reactDemoComponent = DemoComponent;

  constructor() {}

  ngOnInit(): void {}
}
