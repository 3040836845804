import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HelperService } from 'src/app/core/services/helper/helper.service';

@Component({
  selector: 'app-send-message-step',
  templateUrl: './send-message-step.component.html',
})
export class SendMessageStepComponent {
  @Input() isTestMessageSent: boolean;
  @Output() testMessageChange = new EventEmitter<boolean>();
  @Input() phoneForm: FormGroup;
  @Output() setSelectedBizNumber = new EventEmitter();

  qrUrl: string = '';
  selectedBizNumber: any = null;
  internalPhoneNumbers = [
    { phoneNumber: '4930754386792', appId: '133847', partnerId: 'CajhtNPA' },
  ];

  constructor(private helperService: HelperService) {}

  onCheckboxChange(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.testMessageChange.emit(isChecked);
  }

  onSelectedBizNumber(selectedValue: any): void {
    if (selectedValue) {
      this.helperService.showSpinner();
      this.setSelectedBizNumber.emit(selectedValue);
      this.qrUrl = `https://api.qrserver.com/v1/create-qr-code/?data=https://wa.me/${selectedValue.phoneNumber}?text=Test%20message%20from%20360dialog&amp;size=137x137`;
      this.helperService.hideSpinner(false);
    }
  }
}
