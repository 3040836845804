import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { PartnerPBSHistory } from '../domains/models/partner-pbs-history.model';

const params = new HttpParams().set('reset', 'true');

@Injectable({
  providedIn: 'root',
})
export class PbsService {
  pbsRoute: string = `${environment.tppCsToolAPI.url}/pbs`;

  constructor(private httpClient: HttpClient) {}

  // GET

  getPartnerBalanceHistory(
    partnerId: string,
    page: string,
    granularity: string,
    from: string,
    to: string
  ): Observable<PartnerPBSHistory> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
      granularity: granularity,
      from: from,
      page: page,
      to: to,
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<PartnerPBSHistory>(
      `${this.pbsRoute}/partner/${partnerId}/balance/history`,
      { params }
    );
  }

  generatePaymentLink(invoiceId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.pbsRoute}/invoice/${invoiceId}/payment-link`,
      { params }
    );
  }

  generatePDFInvoice(clientId: string, invoiceId: string): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json',
      params: params,
    };
    return this.httpClient.get(
      `${this.pbsRoute}/invoice/client/${clientId}/pdf/${invoiceId}`,
      httpOptions
    );
  }

  // POST
  triggerPayment(invoiceId: string): Observable<any> {
    return this.httpClient.post<any>(
      `${this.pbsRoute}/invoice/${invoiceId}/pay`,
      {}
    );
  }

  refundFullInvoice(invoiceId: string): Observable<any> {
    return this.httpClient.post<any>(
      `${this.pbsRoute}/invoice/${invoiceId}/refund`,
      {}
    );
  }

  refundPartialInvoice(invoiceId: string, body: {}): Observable<any> {
    return this.httpClient.post<any>(
      `${this.pbsRoute}/invoice/${invoiceId}/refund/partial`,
      body
    );
  }

  // PATCH

  // DELETE
}
