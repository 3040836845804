import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-tooltip',
  templateUrl: './info-tooltip.component.html'
})
export class InfoTooltipComponent{
  @Input() labelName: string;
  @Input() tooltipValue: string;
}
