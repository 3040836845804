<div class="modal-header">
  <h5 class="modal-title">{{ title }}</h5>
  <button type="button" class="btn-close" (click)="close()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="form-group">
      <input [type]="inputType" class="form-control" [placeholder]="placeholder" formControlName="inputValue">
    </div>
  </form>
</div>
<div class="modal-footer d-flex justify-content-between">
  <button type="button" class="btn btn-outline-dark" (click)="close()">Dismiss</button>
  <button type="button" class="btn btn-primary" [disabled]="!this.form.valid" (click)="confirm()">{{ confirmationText
    }}</button>
</div>