import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-single-input-modal',
  templateUrl: './single-input-modal.component.html',
})
export class SingleInputModalComponent implements OnInit {
  @Input() title: string = 'Update';
  @Input() maxLength: number;
  @Input() inputType: string = 'text';
  @Input() placeholder: string = 'Update value';
  @Input() confirmationText: string = 'Update';

  form: FormGroup;

  constructor(
    protected activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.generateForm();
  }

  confirm() {
    this.activeModal.close(this.form.controls['inputValue'].value);
  }

  close() {
    this.activeModal.close();
  }

  private generateForm(): void {
    if (this.maxLength) {
      this.form = this.formBuilder.group({
        inputValue: new FormControl(null, [
          Validators.required,
          Validators.maxLength(this.maxLength),
        ]),
      });
    } else {
      this.form = this.formBuilder.group({
        inputValue: new FormControl(null, [Validators.required]),
      });
    }
  }
}
