import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { HubService } from 'src/app/core/services/hub.service';

@Component({
  selector: 'app-block-user-modal',
  templateUrl: './block-user-modal.component.html',
  styleUrls: ['./block-user-modal.component.scss'],
})
export class BlockUserModalComponent {
  @Input() userEmail: string = '';
  @Input() block: boolean = false;

  constructor(
    protected activeModal: NgbActiveModal,
    private helperService: HelperService,
    private hubService: HubService
  ) {}

  close() {
    this.activeModal.dismiss();
  }

  toggleBlockUser(): void {
    this.helperService.showSpinner();
    this.hubService.toggleBlockUser(this.userEmail, !this.block).subscribe({
      error: (err) =>
        this.helperService.openErrorModal('Fail to update user', err),
      complete: () => {
        this.activeModal.close(true);
        this.helperService.hideSpinnerCloseModalAndShowToastr(
          'Updated successfully!'
        );
      },
    });
  }
}
