export const EXTENDED_CREDIT_LINE_ID = {
  data: [
    {
      id: '7452893964828313',
    },
    {
      id: '7084964654954040',
    },
    {
      id: '6677978742312449',
    },
    {
      id: '3214250008690288',
    },
  ],
};

export const EXTENDED_CREDIT_LINE_MAIN_ID = '3214250008690288';
