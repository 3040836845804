import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './core/services/auth.service';
import { HelperService } from './core/services/helper/helper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isSideNavCollapsed: boolean = false;
  screenWidth: number = 0;
  currentUrl: string = '';
  checkedRoleCount = 0;
  isDarkMode: boolean = false;

  constructor(
    private helperService: HelperService,
    public authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.helperService.showSpinner();
    this.authService.isLoading$.subscribe({
      next: (loading) => {
        if (!loading) {
          this.helperService.hideSpinner();
        }
      },
    });
    this.setRedirectUrl();
  }

  copyText(): void {
    this.helperService.copyText(window.location.href);
  }

  private setRedirectUrl(): void {
    const originalUrl = window.location.href;
    const parsedUrl = new URL(originalUrl);
    const path = parsedUrl.pathname + parsedUrl.search;
    localStorage.setItem('redirect_url', path);
  }
}
