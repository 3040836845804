import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { Channel } from 'src/app/core/domains/models/channels.module';
import { OboToSharedModel } from 'src/app/core/domains/models/obo-to-shared.model';
import { CsToolApiService } from 'src/app/core/services/cs-tool-api/cs-tool-api.service';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { OboToSharedService } from 'src/app/core/services/obo-to-shared/obo-to-shared.service';

@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.scss'],
})
export class StepThreeComponent implements OnInit, OnDestroy {
  @Input() channel: Channel;
  @Output() nextStep = new EventEmitter<any>();

  private oboToSharedValSubscription: Subscription;
  oboToEsVal: OboToSharedModel;

  errorMessage: string = '';
  selectedDropdown: string = 'Please select method';

  triggerOtpForm: FormGroup;
  verifyOtpForm: FormGroup;

  constructor(
    private oboToSharedService: OboToSharedService,
    private helperService: HelperService,
    private csToolApiService: CsToolApiService,
    private formBuilder: FormBuilder
  ) {
    this.triggerOtpForm = this.formBuilder.group({
      code_method: new FormControl('', Validators.required),
      language: new FormControl('en'),
      waba_id: new FormControl(),
    });
    this.verifyOtpForm = this.formBuilder.group({
      code: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.subscribeToSharedData();
  }

  ngOnDestroy() {
    this.oboToSharedValSubscription.unsubscribe();
  }

  replaceUnderscore(text) {
    return this.helperService.replaceUnderscore(text);
  }

  dropdownSelected(event): void {
    this.selectedDropdown = event;
    this.helperService.dropdownSelected(
      this.triggerOtpForm,
      event,
      'code_method'
    );
  }

  private subscribeToSharedData() {
    this.oboToSharedValSubscription =
      this.oboToSharedService.oboToSharedVal$.subscribe((payload: any) => {
        this.oboToEsVal = payload;
        this.triggerOtpForm.patchValue({
          waba_id: this.oboToEsVal.new_phone_number_id,
        });
      });
  }

  triggerOtp(): void {
    //   this.errorMessage = '';
    //   this.helperService.showSpinner();
    //   this.triggerOtpForm.patchValue({
    //     waba_id: this.oboToEsVal.new_phone_number_id
    //   });
    //   this.csToolApiService.triggerOnPremOtp({...this.triggerOtpForm.value}).subscribe({
    //     error: (err) => {
    //       this.errorMessage = err.error.errorMessage;
    //       this.helperService.hideSpinner(false);
    //     },
    //     complete: () => {
    //       this.helperService.hideSpinner(false);
    //       this.helperService.showSuccessToastr('OTP triggered successfully.');
    //     }
    //   });
  }

  handleThirdStep(): void {
    // this.errorMessage = '';
    // this.helperService.showSpinner();
    // this.csToolApiService
    //   .handleThirdStep(
    //     this.oboToEsVal.id,
    //     this.verifyOtpForm.controls['code'].value
    //   )
    //   .subscribe({
    //     next: (res) => {
    //       if (res.success) {
    //         this.oboToSharedService.setPayload(res.migration);
    //         this.nextStep.emit('stepThree');
    //       } else {
    //         this.errorMessage = this.oboToSharedService.parseErrorMessage(res);
    //       }
    //       this.helperService.hideSpinner(false);
    //     },
    //     error: (err) => {
    //       this.helperService.hideSpinner(false);
    //       this.helperService.showErrorToastr(
    //         'Failed to verify the number. Please post the issue to cs-tool channel.'
    //       );
    //     },
    //   });
  }
}
