import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';

export interface ModalDetails {
  title: string;
  confirmationText?: string;
  inputs?: ModalInputs[];
}

export interface ModalInputs {
  label: string;
  type?: string;
  value?: string | number;
  required?: boolean;
  max_length?: number;
}

@Component({
  selector: 'app-dynamic-input-modal',
  templateUrl: './dynamic-input-modal.component.html',
})
export class DynamicInputModalComponent implements OnInit {
  @Input() modalDetails: ModalDetails;

  form: FormGroup;

  constructor(
    protected activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.generateForm();
  }

  confirm() {
    if (this.modalDetails.inputs.length == 1) {
      this.activeModal.close(this.form.controls['input0'].value);
    } else {
      this.activeModal.close(this.form.value);
    }
  }

  close() {
    this.activeModal.close();
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({});
    this.modalDetails.inputs?.forEach((input, index) => {
      const validators = [];
      if (input.required === undefined || input.required === true) {
        validators.push(Validators.required);
      }
      if (!!input.max_length) {
        validators.push(Validators.maxLength(input.max_length));
      }
      const control = new FormControl(input.value ?? '', validators);
      this.form.addControl(`input${index}`, control);
    });
  }
}
