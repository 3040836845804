import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { ApplicationSettings } from '../domains/models/application-settings.module';

const params = new HttpParams().set('reset', 'true');

@Injectable({
  providedIn: 'root',
})
export class PltAPIService {
  pltRoute: string = `${environment.tppCsToolAPI.url}/plt`;

  constructor(private httpClient: HttpClient) {}

  // # GET

  getHealthStatus(appId: string, reset: boolean): Observable<any> {
    return this.httpClient.get<any>(
      `${this.pltRoute}/api/v1/stack_actions/health/${appId}`,
      { params }
    );
  }

  getGatewayStatistics(appId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.pltRoute}/api/v1/stack_actions/stats_app/${appId}`,
      { params }
    );
  }

  getApplicationSettings(
    appId: string,
    reset: boolean
  ): Observable<ApplicationSettings> {
    const fromObject: { [index: string]: string } = { reset: reset.toString() };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<ApplicationSettings>(
      `${this.pltRoute}/api/v1/settings/${appId}`,
      { params }
    );
  }

  getWebAppMetrics(appId: string, reset: boolean): Observable<any> {
    const fromObject: { [index: string]: string } = { reset: reset.toString() };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${this.pltRoute}/api/v1/stack_actions/metrics/${appId}`,
      { params }
    );
  }

  getDatabaseSettings(appId: string, reset: boolean): Observable<any> {
    const fromObject: { [index: string]: string } = { reset: reset.toString() };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${this.pltRoute}/api/v1/stack_actions/stats_db/${appId}`,
      { params }
    );
  }

  getCloudWebhook(appId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.pltRoute}/api/v1/stackproxy/webhook/${appId}`,
      { params }
    );
  }

  getPerformanceMessageEnabled(appId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.pltRoute}/api/v1/insights/settings/${appId}`,
      { params }
    );
  }

  // # POST

  syncStack(appId: string, body: {}): Observable<any> {
    return this.httpClient.post(
      `${this.pltRoute}/api/v1/sync_stack/${appId}`,
      body
    );
  }

  finishRegistrationOTP(appId: string, body: {}): Observable<any> {
    return this.httpClient.post(
      `${this.pltRoute}/api/v1/stack_actions/verify_code/${appId}`,
      body
    );
  }

  magicFix(body: {}): Observable<any> {
    return this.httpClient.post(
      `${this.pltRoute}/api/v1/stackproxy/magic_fix`,
      body
    );
  }

  triggerRegistration(appId: string, body: {}): Observable<any> {
    return this.httpClient.post(
      `${this.pltRoute}/api/v1/stack_actions/account/${appId}`,
      body
    );
  }

  // # PATCH

  updateAutoDownload(appId: string, body: {}): Observable<any> {
    return this.httpClient.patch(
      `${this.pltRoute}/api/v1/settings/${appId}`,
      body
    );
  }

  setMaxConcurrentValue(appId: string, body: {}): Observable<any> {
    return this.httpClient.patch(
      `${this.pltRoute}/api/v1/settings/${appId}`,
      body
    );
  }

  restartStack(appId: string): Observable<any> {
    return this.httpClient.patch(
      `${this.pltRoute}/api/v1/restart_stack/${appId}`,
      {}
    );
  }

  updateStatusCallbacks(appId: string, body: {}): Observable<any> {
    return this.httpClient.patch(
      `${this.pltRoute}/api/v1/settings/${appId}`,
      body
    );
  }

  reCreateStack(appId: string, body: {}): Observable<any> {
    return this.httpClient.patch(
      `${this.pltRoute}/api/v1/undelete_stack/${appId}`,
      body
    );
  }

  togglePerformanceMessageEnabled(appId: string, body: {}): Observable<any> {
    return this.httpClient.patch(
      `${this.pltRoute}/api/v1/insights/settings/${appId}`,
      body
    );
  }
}
