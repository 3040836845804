import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CsToolApiService } from 'src/app/core/services/cs-tool-api/cs-tool-api.service';
import { SpinnerService } from './../../../core/services/spinner/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { CsToolApiResponseModel } from 'src/app/core/domains/models/cs-tool-api-response.model';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { WabaUsersModel } from 'src/app/core/domains/models/waba-users.model';
import { WabaInfo } from 'src/app/core/domains/models/waba-info.model';
import { TppMetaService } from 'src/app/core/services/tpp-meta.service';

@Component({
  selector: 'app-set-payment-method-modal',
  templateUrl: './set-payment-method-modal.component.html',
  styleUrls: ['./set-payment-method-modal.component.scss'],
})
export class SetPaymentMethodModalComponent implements OnInit {
  form: FormGroup;
  wabaUsers: WabaUsersModel[] = [];
  loadingData: boolean = true;
  check: boolean = false;
  wabaInfo: WabaInfo = {};
  containsWabaDev: boolean = false;

  displayColumnsUsers: Array<string> = ['ID', 'Name', 'Tasks'];

  constructor(
    private formBuilder: FormBuilder,
    protected activeModal: NgbActiveModal,
    protected csToolApiService: CsToolApiService,
    protected spinnerService: SpinnerService,
    protected toastrService: ToastrService,
    protected helperService: HelperService,
    private tppMetaService: TppMetaService
  ) {}

  ngOnInit(): void {
    this.generateForm();
  }

  checkWaba() {
    this.loadingData = true;
    this.check = true;
    this.wabaUsers = [];
    this.wabaInfo = {};
    this.containsWabaDev = false;
    this.checkWabaDev();
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({
      externalId: new FormControl('', [Validators.required]),
      creditId: new FormControl('3214250008690288', [Validators.required]),
      wabaCurrency: new FormControl('EUR', [Validators.required]),
    });
  }
  close() {
    this.activeModal.dismiss();
  }

  isObjectEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }

  setPaymentMethod(): void {
    this.helperService.showSpinner();
    const body = {
      creditId: this.form.controls['creditId'].value,
      wabaCurrency: this.form.controls['wabaCurrency'].value,
    };
    this.tppMetaService
      .setPaymentMethod(
        this.form.controls['creditId'].value,
        this.form.controls['externalId'].value,
        this.form.controls['wabaCurrency'].value
      )
      .subscribe({
        error: (err) => {
          this.helperService.openErrorModal(
            'Failed to set payment method',
            err
          );
        },
        complete: () => {
          this.helperService.completeWithMessage(
            'Payment method set successfully!'
          );
          this.getWabaInfo();
        },
      });
  }

  assignWabaDev(): void {
    this.helperService.showSpinner();
    this.tppMetaService
      .assignUserToWaba(
        this.form.controls['externalId'].value,
        '112511530302197'
      )
      .subscribe({
        next: () => {
          this.wabaUsers.push({
            id: '112511530302197',
            name: 'waba_dev',
            tasks: ['MANAGE'],
          });
          this.containsWabaDev = true;
          this.getWabaInfo();
        },
        error: (err) => {
          this.helperService.openErrorModal('Failed to Assign Waba DEV.', err);
        },
      });
  }

  private checkWabaDev() {
    this.helperService.showSpinner();
    this.tppMetaService
      .getWabaUsers(this.form.controls['externalId'].value)
      .subscribe({
        next: (res) => {
          this.wabaUsers = res?.data;
          this.containsWabaDev = this.wabaUsers.some(
            (item) => item.id === '112511530302197'
          );
          this.loadingData = false;
          if (this.containsWabaDev) {
            this.getWabaInfo();
          } else {
            this.helperService.hideSpinner(false);
          }
        },
        error: (err) => {
          this.helperService.openErrorModal('Failed to get WABA Users.', err);
        },
      });
  }

  private getWabaInfo(): void {
    this.helperService.showSpinner();
    this.tppMetaService
      .getWabaInfo(this.form.controls['externalId'].value)
      .subscribe({
        next: (res) => {
          this.wabaInfo = res;
          this.helperService.hideSpinner(false);
        },
        error: (err) => {
          this.helperService.openErrorModal('Failed to get WABA Info.', err);
        },
      });
  }
}
