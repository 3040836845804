<div class="modal-header">
    <div class="d-inline-flex align-items-center">
        <h4 class="modal-title me-2">Add TOS Type</h4>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
    <span>Are you sure you want to {{ block ? 'unblock': 'block' }} <b>{{ userEmail }}</b>?</span>
</div>
<div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-danger" (click)="close()">Close</button>
    <button type="button" class="btn btn-primary" type="submit" (click)="toggleBlockUser()">
        {{ block ? "Unblock" : "Block" }}
    </button>
</div>