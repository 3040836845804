<div class="modal-header">
    <div class="d-inline-flex align-items-center">
        <h4 class="modal-title me-2">WABA Phone Numbers</h4>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
    <form [formGroup]="form">
        <div class="form-group">
            <label>External ID</label>
            <input type="text" class="form-control" formControlName="wabaId">
        </div>
    </form>
</div>
<div *ngIf="response" class="p-1">
    <ngx-json-viewer [json]="response"></ngx-json-viewer>
</div>
<div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-danger" (click)="close()">Close</button>
    <button type="button" class="btn btn-primary" type="submit" [disabled]="!this.form.valid" (click)="confirm()">
        Proceed
    </button>
</div>