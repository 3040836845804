<div>
    <h6 class="fb mb-3">Step 3 - Send Biz Message</h6>
    <form [formGroup]="phoneForm">
        <label>Phone Number (with country code)</label>
        <div class="input-group">
            <input type="text" class="form-control" formControlName="phoneNumber">
            <div class="input-group-prepend">
                <button class="btn btn-primary" type="button" [disabled]="!phoneForm.valid"
                    (click)="onSendMessage()">Send message</button>
            </div>
        </div>
    </form>
</div>