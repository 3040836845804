import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { Campaigns } from '../domains/models/campaigns.module';
import { Ads } from '../domains/models/ads.module';

const params = new HttpParams().set('reset', 'true');

@Injectable({
  providedIn: 'root',
})
export class InsightService {
  insightRoute: string = `${environment.tppCsToolAPI.url}/insight`;

  constructor(private httpClient: HttpClient) {}

  // GET
  checkPerformanceMessaging(client_id): Observable<any> {
    const fromObject: { [index: string]: string } = {
      reset: 'true',
      client_ids: client_id,
      offset: '0',
      limit: '20',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${this.insightRoute}/v1/performance-messaging-clients/`,
      { params }
    );
  }

  getAllowedClient(clientId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.insightRoute}/v1/insights-allowed/${clientId}`
    );
  }

  getCampaigns(
    clientId: string,
    appId: string,
    adAccountId: string,
    offset: string,
    size: string
  ): Observable<Campaigns> {
    const fromObject: { [index: string]: string } = {
      offset: offset,
      limit: size.toString(),
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<Campaigns>(
      `${this.insightRoute}/v1/clients/${clientId}/apps/${appId}/campaigns/account/${adAccountId}`,
      { params }
    );
  }

  getAds(
    clientId: string,
    appId: string,
    adAccountId: string,
    offset: string,
    size: string
  ): Observable<Ads> {
    const fromObject: { [index: string]: string } = {
      offset: offset,
      limit: size.toString(),
      reset: 'true',
    };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<Ads>(
      `${this.insightRoute}/v1/clients/${clientId}/apps/${appId}/ads/account/${adAccountId}`,
      { params }
    );
  }

  getFacebookAccounts(clientId: string, appId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${this.insightRoute}/v1/clients/${clientId}/apps/${appId}/facebook-accounts`
    );
  }

  // POST
  enableClientForInsight(body: {}): Observable<any> {
    return this.httpClient.post<any>(
      `${this.insightRoute}/v1/insights-allowed/`,
      body
    );
  }

  // PATCH

  // DELETE
  disableClientForInsight(clientId: string): Observable<any> {
    return this.httpClient.delete<any>(
      `${this.insightRoute}/v1/insights-allowed/${clientId}`
    );
  }
}
