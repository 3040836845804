import { ModalDetails } from './dynamic-input-modal.component';

export const ACTION_DELETE_USER_MODAL: ModalDetails = {
  title: 'Delete user (completely)',
  confirmationText: 'Delete',
  inputs: [
    {
      label: 'User email',
    },
  ],
};

export const ACTION_PORT_NUMBER_MODAL: ModalDetails = {
  title: 'Port Number',
  confirmationText: 'Proceed',
  inputs: [
    {
      label: 'Partner ID',
    },
    {
      label: 'Client ID',
    },
    {
      label: 'WABA ID',
    },
    {
      label: 'Phone Number',
    },
  ],
};

export const ACTION_GENERATE_PAYMENT_LINK_MODAL: ModalDetails = {
  title: 'Generate payment link',
  confirmationText: 'Generate',
  inputs: [
    {
      label: 'Invoice ID',
    },
  ],
};

export const ACTION_FLAG_FOR_RE_ONBOARDING_MODAL: ModalDetails = {
  title: 'Flag for re-onboarding',
  confirmationText: 'Proceed',
  inputs: [
    {
      label: 'Channel ID',
    },
  ],
};

export const ACTION_GENERATE_CLIENT_INVOICE_PDF_MODAL: ModalDetails = {
  title: 'Generate PDF invoice',
  confirmationText: 'Generate',
  inputs: [
    {
      label: 'Client ID'
    },
    {
      label: 'Invoice ID'
    },
  ],
};

export const ACTION_ALLOW_OUTBOUND_MESSAGES_MODAL: ModalDetails = {
  title: 'Allow outbound messages',
  confirmationText: 'Allow',
  inputs: [
    {
      label: 'Partner ID',
    },
    {
      label: 'Client ID',
    },
  ],
};

export const SETUP_DETAILS_UPDATE_EXTERNAL_ID_MODAL: ModalDetails = {
  title: 'Update Channel External ID',
  confirmationText: 'Update',
  inputs: [
    {
      label: 'External ID',
    },
  ],
};

export const SETUP_DETAILS_UPDATE_MAX_CHANNELS_MODAL: ModalDetails = {
  title: 'Update Max Channels',
  confirmationText: 'Update',
  inputs: [
    {
      label: 'Max Channels',
      type: 'number',
    },
  ],
};

export const COMMENT_ADD_COMMENT_MODAL: ModalDetails = {
  title: 'Add Comment',
  confirmationText: 'Add',
  inputs: [
    {
      label: 'Comment',
    },
  ],
};

export const PCR_REJECT_MODAL: ModalDetails = {
  title: 'Reject PCR',
  confirmationText: 'Confirm',
  inputs: [
    {
      label: 'Reason',
    },
  ],
};
