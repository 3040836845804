import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { TppMetaService } from 'src/app/core/services/tpp-meta.service';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { EMPTY, throwError } from 'rxjs';
import { EXTENDED_CREDIT_LINE_MAIN_ID } from '../constants';

const AVAILABLE_CURRENCIES = [
  'USD',
  'EUR',
  'GBP',
  'AUD',
  'IDR',
  'INR',
] as const;
type Currency = (typeof AVAILABLE_CURRENCIES)[number];

interface CreditLineState {
  error: boolean;
  errorMessage: string;
  paymentMethodAttached: boolean;
  wabaCurrency: string;
  isAccessRevoked: boolean;
  businessPortfolioId: string;
  creditAllocationConfigId: string;
}

@Component({
  selector: 'app-nav-waba',
  templateUrl: './nav-waba.component.html',
  styleUrls: ['./nav-waba.component.scss'],
})
export class NavWabaComponent implements OnInit {
  form: FormGroup<{
    wabaId: FormControl<string>;
    selectedCurrency: FormControl<Currency>;
  }>;
  readonly currencies = AVAILABLE_CURRENCIES;

  state: CreditLineState = {
    error: false,
    errorMessage: '',
    paymentMethodAttached: false,
    wabaCurrency: '',
    isAccessRevoked: false,
    businessPortfolioId: '',
    creditAllocationConfigId: '',
  };

  constructor(
    private fb: FormBuilder,
    private helperService: HelperService,
    private tppMetaService: TppMetaService
  ) {}

  ngOnInit(): void {
    this.resetState();
    this.initForm();
  }

  getWabaPrimaryFundingId(): void {
    if (this.form.get('wabaId').invalid) {
      return;
    }

    const wabaId = this.form.get('wabaId').value;
    this.resetState();
    this.helperService.showSpinner();

    this.tppMetaService
      .getWabaPrimaryFundingId(wabaId)
      .pipe(
        switchMap((res) => {
          if (
            !res?.primary_funding_id ||
            res.primary_funding_id === '' ||
            res.primary_funding_id === '0'
          ) {
            return throwError(() => ({
              error: {
                message: 'No funding ID found for the WABA.',
              },
            }));
          }

          this.state.wabaCurrency = res.currency;
          return this.tppMetaService.getCreditLineInformation(
            res.primary_funding_id
          );
        }),
        switchMap((res) => {
          if (!res) {
            return throwError(() => ({
              error: {
                message: 'Fail to get credit line information',
              },
            }));
          }
          this.state.businessPortfolioId = res.owner_business?.id;
          this.state.isAccessRevoked = res.is_access_revoked;
          if (this.state.isAccessRevoked) {
            return throwError(() => ({
              error: {
                message: 'Credit line is revoked.',
              },
            }));
          }
          if (!res.receiving_credit_allocation_config?.id) {
            return throwError(() => ({
              error: {
                message: 'Fail to get credit allocation config.',
              },
            }));
          }
          this.state.creditAllocationConfigId =
            res.receiving_credit_allocation_config.id;
          return this.tppMetaService.getOwningCredentials(
            res.receiving_credit_allocation_config.id
          );
        }),
        tap((res) => {
          if (!res) {
            return throwError(() => ({
              error: {
                message: 'Fail to get owning credentials.',
              },
            }));
          } else {
            if (res.owning_credential?.id == EXTENDED_CREDIT_LINE_MAIN_ID) {
              this.state.paymentMethodAttached = true;
            } else {
              return throwError(() => ({
                error: {
                  message: 'Invalid owning credentials.',
                },
              }));
            }
            return EMPTY;
          }
        }),
        catchError((error) => {
          this.state.error = true;
          this.state.errorMessage = this.helperService.errorHandle(error);
          return EMPTY;
        }),
        finalize(() => this.helperService.hideSpinner(false))
      )
      .subscribe();
  }

  revokeCreditLine(): void {
    this.helperService.showSpinner();
    this.tppMetaService
      .deleteCreditLine(this.state.creditAllocationConfigId)
      .subscribe({
        error: (err) => {
          this.state.error = true;
          this.state.errorMessage = this.helperService.errorHandle(err);
        },
        complete: () => {
          this.helperService.showSuccessToastr(
            'Credit line removed successfully.'
          );
          this.getWabaPrimaryFundingId();
        },
      });
  }

  attachPaymentMethod(): void {
    if (this.form.invalid) {
      return;
    }

    const { wabaId, selectedCurrency } = this.form.value;
    this.helperService.showSpinner();

    this.tppMetaService
      .getCustomerBusinessPortfolioId(wabaId)
      .pipe(
        switchMap((res) => {
          const businessPortfolioId = res?.owner_business_info?.id;
          if (!businessPortfolioId) {
            return throwError(() => ({
              error: {
                message: 'No business portfolio ID found',
              },
            }));
          }
          return this.tppMetaService.shareCreditLineWithCustomerBusiness(
            EXTENDED_CREDIT_LINE_MAIN_ID,
            businessPortfolioId
          );
        }),
        switchMap((res) => {
          if (!res?.success) {
            return throwError(() => ({
              error: {
                message: res?.message || 'Failed to share credit line',
              },
            }));
          }
          if (this.state.isAccessRevoked) {
            this.getWabaPrimaryFundingId();
            return EMPTY;
          }
          return this.tppMetaService.setPaymentMethod(
            EXTENDED_CREDIT_LINE_MAIN_ID,
            wabaId,
            selectedCurrency
          );
        }),
        switchMap((res) => {
          if (!res?.success) {
            return throwError(() => ({
              error: {
                message: 'Failed to set payment method',
              },
            }));
          }
          this.getWabaPrimaryFundingId();
          return EMPTY;
        }),
        catchError((error) => {
          const errorMessage = this.helperService.errorHandle(error);
          this.state.error = true;
          this.state.errorMessage = errorMessage;
          this.helperService.hideSpinner(false);
          return EMPTY;
        })
      )
      .subscribe({
        next: () => {
          this.helperService.showSuccessToastr(
            'Payment method attached successfully'
          );
        },
      });
  }

  private initForm(): void {
    this.form = this.fb.group({
      wabaId: ['', [Validators.required]],
      selectedCurrency: ['USD' as Currency, [Validators.required]],
    });
  }

  private resetState(): void {
    this.state = {
      error: false,
      errorMessage: '',
      paymentMethodAttached: false,
      wabaCurrency: '',
      isAccessRevoked: false,
      businessPortfolioId: '',
      creditAllocationConfigId: '',
    };
  }
}
