import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { BusinessCompliance } from '../domains/models/business-compliance.module';

const params = new HttpParams().set('reset', 'true');

@Injectable({
  providedIn: 'root',
})
export class CloudService {
  cloudRoute: string = `${environment.tppCsToolAPI.url}/cloud`;

  constructor(private httpClient: HttpClient) {}

  // # GET

  getCloudApiBusinessCompliance(
    partnerId: string,
    appId: string,
    reset: boolean
  ): Observable<BusinessCompliance> {
    const fromObject: { [index: string]: string } = { reset: reset.toString() };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<BusinessCompliance>(
      `${this.cloudRoute}/${partnerId}/${appId}/business_compliance_info`,
      { params }
    );
  }

  getCloudApiHealthStatus(
    partnerId: string,
    appId: string,
    reset: boolean
  ): Observable<any> {
    const fromObject: { [index: string]: string } = { reset: reset.toString() };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<any>(
      `${this.cloudRoute}/${partnerId}/${appId}/health_status`,
      { params }
    );
  }

  getMultiWebhookInformation(
    partnerId: string,
    appId: string
  ): Observable<any> {
    return this.httpClient.get<any>(
      `${this.cloudRoute}/${partnerId}/${appId}/multi_webhook`,
      { params }
    );
  }

  // # POST

  updateCloudApiBusinessCompliance(
    partnerId: string,
    appId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post(
      `${this.cloudRoute}/${partnerId}/${appId}/business_compliance_info`,
      body
    );
  }

  sendCloudApiMessage(
    partnerId: string,
    appId: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post(
      `${this.cloudRoute}/${partnerId}/${appId}/messages`,
      body
    );
  }

  updateCloudApiWebhook(
    partnerId: string,
    appId: string,
    body: {}
  ): Observable<any> {
    var targetUrl = '';
    if (body['type'] == 'channel') {
      targetUrl = `${this.cloudRoute}/${partnerId}/${appId}/v1/configs/webhook`;
    } else {
      targetUrl = `${this.cloudRoute}/${partnerId}/${appId}/waba_webhook`;
    }
    delete body['type'];
    return this.httpClient.post(targetUrl, body);
  }

  // # PATCH

  // # DELETE
}
