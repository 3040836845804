import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { WabaAccount } from 'src/app/core/domains/models/channels.module';
import { Partners } from 'src/app/core/domains/models/partners.module';
import { RequestQueryModel } from 'src/app/core/domains/models/request-query.model';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { HubService } from 'src/app/core/services/hub.service';
import { WabaHelperService } from 'src/app/core/services/waba-helper.service';
import {
  WabaExportData,
  WabaProcessStatus,
} from './share-waba-to-solution.modal';

@Component({
  selector: 'app-share-waba-to-solution',
  templateUrl: './share-waba-to-solution.component.html',
  styleUrls: ['./share-waba-to-solution.component.scss'],
})
export class ShareWabaToSolutionComponent implements OnInit, OnDestroy {
  form: FormGroup;
  selectedPartnerId: string = '';
  solutionId: string = '';
  partners: Partners = {} as Partners;
  wabas: WabaAccount[] = [];
  selectedWaba: { id: string; name: string }[] = [];
  loadingPartner: boolean = true;
  loadingWaba: boolean = true;
  wabaSearchTerm: string = '';
  wabaProcessingStatus: WabaProcessStatus[] = [];
  isExporting: boolean = false;

  private static readonly CHUNK_SIZE = 1000;

  private destroy$ = new Subject<void>();
  private activeUrls: string[] = [];

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private hubService: HubService,
    private wabaHelperService: WabaHelperService
  ) {}

  ngOnInit(): void {
    this.generateForm();
  }

  ngOnDestroy(): void {
    this.activeUrls.forEach((url) => URL.revokeObjectURL(url));

    this.destroy$.next();
    this.destroy$.complete();

    this.resetAll();
    this.form.reset();
  }

  close(): void {
    this.activeModal.dismiss();
  }

  get successCount(): number {
    return this.wabaProcessingStatus.filter((status) => status.success).length;
  }

  get failCount(): number {
    return this.wabaProcessingStatus.filter((status) => !status.success).length;
  }

  onPartnerCheckboxChange(
    event: Event,
    partnerId: string,
    solutionId: string
  ): void {
    this.resetAll();
    if ((event.target as HTMLInputElement).checked) {
      this.selectedPartnerId = partnerId;
      this.solutionId = solutionId;
      this.getPartnerWabaIds(partnerId);
    }
  }

  resetAll(): void {
    this.selectedPartnerId = '';
    this.solutionId = '';
    this.wabas = [];
    this.selectedWaba = [];
  }

  isWabaSelected(waba: WabaAccount): boolean {
    return this.selectedWaba.some((w) => w.id === waba.external_id);
  }

  onWabaSelect(event: Event, externalId: string, name: string): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      if (!this.selectedWaba.some((w) => w.id === externalId)) {
        this.selectedWaba.push({ id: externalId, name });
      }
    } else {
      this.selectedWaba = this.selectedWaba.filter((w) => w.id !== externalId);
    }
  }

  onSelectAll(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.selectedWaba = this.wabas.map((waba) => ({
        id: waba.external_id,
        name: waba.name,
      }));
    } else {
      this.selectedWaba = [];
    }
  }

  startProcess(): void {
    this.wabaProcessingStatus = [];
    const body = {
      solution_id: this.solutionId,
      waba_list: this.selectedWaba,
    };
    this.helperService.showSpinner();
    this.wabaHelperService
      .assignWabaToMPS(body)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          this.wabaProcessingStatus = res;
          this.helperService.hideSpinner(false);
        },
        error: (err) => {
          this.helperService.showErrorToastr(
            'An unexpected error occurred. Please report the issue to #cs-tool or create a ticket.'
          );
          this.helperService.hideSpinner(false);
          throw new Error(
            `An unexpected error occurred in processing waba: ${JSON.stringify(
              err
            )}`
          );
        },
      });
  }

  getPartnerWabaIds(partnerId: string): void {
    this.loadingWaba = true;
    this.helperService.showSpinner();
    this.hubService
      .getPartnerWabaIds(partnerId)
      .pipe(
        finalize(() => {
          this.helperService.hideSpinner(false);
          this.loadingWaba = false;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (res) => {
          this.wabas = res;
        },
        error: (err) => {
          this.helperService.showErrorToastr(
            this.helperService.parseHubErrorMessage(err)
          );
        },
      });
  }

  searchPartner(): void {
    this.resetAll();
    this.loadingPartner = true;
    const filters: { [key: string]: string } = {};
    const partnerIdRegex: RegExp = /^.*PA$/;
    const partnerFilter = this.form.controls['partnerFilter'].value;

    if (partnerIdRegex.test(partnerFilter)) {
      filters['partnerId'] = partnerFilter.trim();
    } else {
      filters['partnerName'] = partnerFilter;
    }

    this.helperService.showSpinner();
    const requestQuery: RequestQueryModel = {
      page: 1,
      size: 50,
      filters,
    };

    this.hubService
      .getPartners(true, '0', requestQuery)
      .pipe(
        finalize(() => {
          this.loadingPartner = false;
          this.helperService.hideSpinner(false);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (res) => {
          this.partners = res;
        },
        error: (err) => {
          this.helperService.showErrorToastr(
            `Failed to get partner's. Error ${this.helperService.parseHubErrorMessage(
              err
            )}`
          );
        },
      });
  }

  private generateForm(): void {
    this.form = this.formBuilder.group({
      partnerFilter: new FormControl('', [Validators.required]),
    });
  }

  exportWabaData(
    filterFn: (status: WabaProcessStatus) => boolean,
    fileSuffix: string,
    headers: string[]
  ): void {
    this.startExport();
    try {
      const filteredData: WabaExportData[] = this.wabaProcessingStatus
        .filter(filterFn)
        .map((status) => ({
          ID: status.id,
          NAME: status.name,
          STATUS: status.success ? 'Success' : 'Failed',
          ERROR_MESSAGE:
            status.error?.error_user_msg || JSON.stringify(status.error),
        }));
      this.exportToCSV(
        filteredData,
        `${this.form.controls['partnerFilter'].value}-${fileSuffix}-wabas`,
        headers
      );
    } catch (error) {
      this.handleExportError(error);
    } finally {
      this.endExport();
    }
  }

  exportSuccess(): void {
    this.exportWabaData((status) => status.success, 'success', ['ID', 'NAME']);
  }

  exportFailed(): void {
    this.exportWabaData((status) => !status.success, 'failed', [
      'ID',
      'NAME',
      'ERROR_MESSAGE',
    ]);
  }

  exportAll(): void {
    this.exportWabaData(() => true, 'combined', [
      'ID',
      'NAME',
      'STATUS',
      'ERROR_MESSAGE',
    ]);
  }

  private exportToCSV(
    data: WabaExportData[],
    filename: string,
    headers: string[]
  ): void {
    if (!this.validateData(data)) {
      return;
    }

    try {
      if (data.length > 2000) {
        const chunks = this.chunkArray(
          data,
          ShareWabaToSolutionComponent.CHUNK_SIZE
        );
        chunks.forEach((chunk, index) => {
          const chunkFilename = `${filename}_part${index + 1}`;
          this.createAndDownloadCSV(chunk, chunkFilename, headers);
        });
      } else {
        this.createAndDownloadCSV(data, filename, headers);
      }
    } catch (error) {
      this.handleExportError(error);
      console.error('Export error:', error);
    }
  }

  private chunkArray<T>(array: T[], size: number): T[][] {
    const chunks: T[][] = [];
    for (let i = 0; i < array.length; i += size) {
      chunks.push(array.slice(i, i + size));
    }
    return chunks;
  }

  private createAndDownloadCSV(
    data: WabaExportData[],
    filename: string,
    headers: string[]
  ): void {
    const csvRows = [
      headers.join(','),
      ...data.map((row) =>
        headers
          .map((header) => {
            const value = row[header as keyof WabaExportData];
            return `"${String(value || '').replace(/"/g, '""')}"`;
          })
          .join(',')
      ),
    ];

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], {
      type: 'text/csv;charset=utf-8;',
    });
    const url = URL.createObjectURL(blob);
    this.activeUrls.push(url);

    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute(
      'download',
      `${filename}_${new Date().toISOString().split('T')[0]}.csv`
    );
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => {
      URL.revokeObjectURL(url);
      this.activeUrls = this.activeUrls.filter((u) => u !== url);
    }, 100);
  }

  private validateData(data: WabaExportData[]): boolean {
    if (!Array.isArray(data) || data.length === 0) {
      this.helperService.showErrorToastr('No valid data to export');
      return false;
    }
    const hasInvalidData = data.some(
      (item) => !item.ID || !item.NAME || !item.STATUS
    );

    if (hasInvalidData) {
      this.helperService.showErrorToastr('Invalid data format detected');
      return false;
    }

    return true;
  }

  private handleExportError(error: unknown): void {
    const errorMessage =
      error instanceof Error
        ? error.message
        : 'An unknown error occurred during export';

    this.helperService.showErrorToastr(
      `Failed to export data: ${this.helperService.parseHubErrorMessage(
        errorMessage
      )}`
    );
    console.error('Export error:', error);
  }

  private startExport(): void {
    this.isExporting = true;
  }

  private endExport(): void {
    this.isExporting = false;
  }

  get filteredWabas(): WabaAccount[] {
    if (!this.wabaSearchTerm) {
      return this.wabas;
    }
    const term = this.wabaSearchTerm.toLowerCase();
    return this.wabas.filter(
      (waba) =>
        waba.name.toLowerCase().includes(term) ||
        waba.external_id.toLowerCase().includes(term)
    );
  }
}
