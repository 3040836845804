import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import {
  AuditLog,
  GetAuditLogsOptions,
} from '../domains/models/audit-logs.model';
import { HelperService } from './helper/helper.service';

@Injectable({
  providedIn: 'root',
})
export class AuditLogService {
  auditLogRoute: string = `${environment.tppCsToolAPI.url}/audit-log`;

  constructor(
    private httpClient: HttpClient,
    private helperService: HelperService
  ) {}

  getAuditLogs(options: GetAuditLogsOptions): Observable<AuditLog[]> {
    const { reset, offset, limit, sort, entity_id } = options;
    const paramsObject = this.helperService.filterUndefinedProperties({
      reset: reset,
      offset: offset,
      limit: limit,
      sort: sort,
      entity_id: entity_id,
    });
    const params =
      Object.keys(paramsObject).length > 0
        ? new HttpParams({ fromObject: paramsObject })
        : null;
    return this.httpClient.get<AuditLog[]>(
      `${this.auditLogRoute}/v1/internal`,
      {
        params,
      }
    );
  }
}
