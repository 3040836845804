import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { HelperService } from 'src/app/core/services/helper/helper.service';
import { TppMetaService } from 'src/app/core/services/tpp-meta.service';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { EMPTY, throwError } from 'rxjs';
import { EXTENDED_CREDIT_LINE_MAIN_ID } from '../constants';

interface CreditLineState {
  error: boolean;
  errorMessage: string;
  allocationId: string;
  noAllocationId: boolean;
}

@Component({
  selector: 'app-nav-business-manager',
  templateUrl: './nav-business-manager.component.html',
  styleUrls: ['./nav-business-manager.component.scss'],
})
export class NavBusinessManagerComponent implements OnInit {
  form: FormGroup<{
    businessManagerId: FormControl<string>;
  }>;

  state: CreditLineState = {
    error: false,
    errorMessage: '',
    allocationId: '',
    noAllocationId: false,
  };

  constructor(
    private fb: FormBuilder,
    private helperService: HelperService,
    private tppMetaService: TppMetaService
  ) {}

  ngOnInit(): void {
    this.resetState();
    this.initForm();
  }

  getAllocationId(): void {
    const businessManagerControl = this.form.get('businessManagerId');
    if (businessManagerControl.invalid) {
      return;
    }
    const businessManagerId = businessManagerControl.value;
    this.resetState();
    this.helperService.showSpinner();
    this.tppMetaService.getAllocationId(businessManagerId).subscribe({
      error: (err) => {
        this.state.error = true;
        this.state.errorMessage = this.helperService.errorHandle(err);
        this.helperService.hideSpinner(false);
      },
      next: (res) => {
        if (res?.data?.length === 0) {
          this.state.noAllocationId = true;
        } else {
          this.state.allocationId = res.data[0].id;
        }
      },
      complete: () => {
        this.helperService.hideSpinner(false);
      },
    });
  }

  revokeCreditLine(): void {
    this.helperService.showSpinner();
    this.tppMetaService.deleteCreditLine(this.state.allocationId).subscribe({
      error: (err) => {
        this.state.error = true;
        this.state.errorMessage = this.helperService.errorHandle(err);
        this.helperService.hideSpinner(false);
      },
      complete: () => {
        this.verifyCreditLineDeleted();
      },
    });
  }

  private verifyCreditLineDeleted(): void {
    this.tppMetaService
      .verifyCreditLineDeleted(this.state.allocationId)
      .subscribe({
        next: (res) => {
          if (res?.data?.request_status === 'DELETED') {
            this.state.noAllocationId = true;
          } else {
            this.state.error = true;
            this.state.errorMessage =
              'Failed to delete credit line. Please reach out in #cs-tool channel or create a ticket';
          }
        },
        error: (err) => {
          this.state.error = true;
          this.state.errorMessage = this.helperService.errorHandle(err);
          this.helperService.hideSpinner(false);
        },
      });
  }

  private initForm(): void {
    this.form = this.fb.group({
      businessManagerId: ['', [Validators.required]],
    });
  }

  private resetState(): void {
    this.state = {
      error: false,
      errorMessage: '',
      allocationId: '',
      noAllocationId: false,
    };
  }
}
