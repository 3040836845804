import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';

const params = new HttpParams().set('reset', 'true');

@Injectable({
  providedIn: 'root',
})
export class CustomRequestService {
  customRequestRoute: string = `${environment.tppCsToolAPI.url}/cr`;

  constructor(private httpClient: HttpClient) {}

  executeGetRequest(
    appId: string,
    partnerId: string,
    url: string
  ): Observable<any> {
    return this.httpClient.get(
      `${this.customRequestRoute}/app/${appId}/partner/${partnerId}/url/${url}`,
      { observe: 'response' }
    );
  }

  executePostRequest(
    appId: string,
    partnerId: string,
    url: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.post(
      `${this.customRequestRoute}/app/${appId}/partner/${partnerId}/url/${url}`,
      body,
      { observe: 'response' }
    );
  }

  executePatchRequest(
    appId: string,
    partnerId: string,
    url: string,
    body: {}
  ): Observable<any> {
    return this.httpClient.patch(
      `${this.customRequestRoute}/app/${appId}/partner/${partnerId}/url/${url}`,
      body,
      { observe: 'response' }
    );
  }
}
