import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { AuthService, User } from '@auth0/auth0-angular';
import { takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { AuthUserModel } from '../domains/models/auth-user.model';
import { SharedDataService } from './shared-data/shared-data.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { UserModel } from '../domains/models/user.model';
import { HelperService } from './helper/helper.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements OnDestroy {
  private _isLoggedIn$ = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this._isLoggedIn$.asObservable();
  private _isLoading$ = new BehaviorSubject<boolean>(true);
  isLoading$ = this._isLoading$.asObservable();
  private destroy$ = new Subject<void>();
  user: AuthUserModel = {};

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private auth0Service: AuthService,
    private httpClient: HttpClient,
    private router: Router,
    private userService: UserService,
    private sharedDataService: SharedDataService,
    private helperService: HelperService
  ) {
    this.initialize();
  }

  login(): void {
    this.auth0Service.loginWithPopup().subscribe({
      complete: () => this.initialize(),
    });
  }

  logout(): void {
    this.clearAuthState();
    this._isLoggedIn$.next(false);
    this._isLoading$.next(false);
  }

  private initialize(): void {
    this._isLoading$.next(true);
    combineLatest([
      this.auth0Service.isAuthenticated$,
      this.auth0Service.isLoading$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([isAuthenticated, isLoading]) => {
        this._isLoading$.next(isLoading);
        if (!isLoading) {
          this._isLoggedIn$.next(isAuthenticated);
          if (isAuthenticated) {
            this.auth0Service.user$
              .pipe(takeUntil(this.destroy$))
              .subscribe(async (user) => {
                const userModel: UserModel = {
                  id: user.sub,
                  email: user.email,
                  name: user.name,
                };
                this.checkUser(userModel);
                this.checkPermissions();
                this._isLoggedIn$.next(true);
                this.user = user;
                this.setInfoToLocalStorage(user?.email);
              });
          }
        }
      });
  }

  private checkPermissions(): void {
    this.getUserProfile().subscribe({
      next: (res) => {
        this.sharedDataService.setUserValue(res);
      },
    });
  }

  private getUserProfile(): Observable<AuthUserModel> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<AuthUserModel>(
      `${environment.tppCsToolAPI.url}/v1/authorization/user_profile`,
      { params }
    );
  }

  private checkUser(user: UserModel) {
    this.userService.getUserByUserId(user.id).subscribe({
      next: (res) => this.sharedDataService.setUser(res),
      error: (err) => {
        if (err.status == 404) {
          this.createUser(user);
        }
      },
    });
  }

  private createUser(user: UserModel): void {
    this.userService.createUser(user).subscribe({
      next: (res) => this.sharedDataService.setUser(res),
      error: (err) =>
        this.helperService.showErrorToastr(
          'Fail to create user. Please report the issue to #cs-tool.'
        ),
    });
  }

  private setInfoToLocalStorage(email: string): void {
    localStorage.setItem('hub_email', email);
    const name = email
      .split('@')[0]
      .replace('.', ' ')
      .replace(/\b\w/g, (c) => c.toUpperCase());
    localStorage.setItem('hub_username', name);
  }

  clearAuthState(): void {
    this.auth0Service.logout({
      logoutParams: { returnTo: this.doc.location.origin },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  redirectAfterLogin(): void {
    const redirectLink = localStorage.getItem('redirect_url');
    if (redirectLink === '/login') {
      this.router.navigateByUrl('/phone');
    } else if (redirectLink) {
      window.location.href = redirectLink;
    }
  }
}
