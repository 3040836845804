<div class="row align-items-center text-center mh-100">
  <div class="col-sm">
    <div class="d-flex justify-content-center">
      <div class="card">
        <div class="card-body text-start p-5">
          <h5 class="card-title">CS Tool Login</h5>
          <form>
            <button type="button" class="btn btn-lg btn-primary ha" type="submit" (click)="login()">
              Login
            </button>
          </form>
          <p class="card-text pt-3">
            ©{{ currentYear }} All Rights Reserved. <br />
            <a class="text-dark" href="https://www.360dialog.com/en/contact/#dataprivacy">Privacy,</a>
            and
            <a class="text-dark" href="https://www.360dialog.com/en/contact">Imprint</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>