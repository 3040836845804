import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  timer,
  throwError,
} from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService, User } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { AuthUserModel } from '../domains/models/auth-user.model';
import { SharedDataService } from './shared-data/shared-data.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
import { UserModel } from '../domains/models/user.model';
import { HelperService } from './helper/helper.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements OnDestroy {
  private _isLoggedIn$ = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this._isLoggedIn$.asObservable();
  private _isLoading$ = new BehaviorSubject<boolean>(true);
  isLoading$ = this._isLoading$.asObservable();
  private destroy$ = new Subject<void>();
  user: AuthUserModel = {};
  private tokenRefreshTimer$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private auth0Service: AuthService,
    private httpClient: HttpClient,
    private router: Router,
    private userService: UserService,
    private sharedDataService: SharedDataService,
    private helperService: HelperService
  ) {
    this.initialize();
  }

  login(): void {
    this.auth0Service.loginWithPopup().subscribe({
      error: (error) => {
        if (error.error !== 'cancelled') {
          this.helperService.showErrorToastr(
            'An unexpected error occurred during login.'
          );
          throw new Error(`Login error: ${error}`);
        }
      },
      complete: () => this.initialize(),
    });
  }

  logout(): void {
    this.clearAuthState();
    this.auth0Service.logout({
      logoutParams: { returnTo: this.doc.location.origin },
    });
  }

  private initialize(): void {
    this._isLoading$.next(true);

    combineLatest([
      this.auth0Service.isAuthenticated$,
      this.auth0Service.isLoading$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([isAuthenticated, isLoading]) => {
        this._isLoading$.next(isLoading);

        if (isAuthenticated) {
          this._isLoggedIn$.next(true);

          this.auth0Service.user$
            .pipe(takeUntil(this.destroy$))
            .subscribe((user) => {
              if (user) {
                this.handleAuthenticatedUser(user);
              }
            });

          this.setupTokenRefresh();
        } else {
          this._isLoggedIn$.next(false);
        }
      });
  }

  private setupTokenRefresh(): void {
    if (this.tokenRefreshTimer$.observers.length > 0) {
      return; // Prevent duplicate timers
    }

    timer(0, 15 * 60 * 1000)
      .pipe(
        switchMap(() =>
          this.auth0Service.getAccessTokenSilently({
            detailedResponse: true,
          })
        ),
        catchError((error) => {
          console.error('Error refreshing token:', error);
          this.helperService.showErrorToastr(
            'Session expired. Please log in again.'
          );
          this.logout();
          return throwError(() => new Error('Token refresh failed'));
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((response) => {
        console.log('Token refreshed successfully:', response);
      });
  }

  private handleAuthenticatedUser(user: User): void {
    const userModel: UserModel = {
      id: user.sub || '',
      email: user.email || '',
      name: user.name || '',
    };

    this.checkUser(userModel);
    this.checkPermissions();
    this.setInfoToLocalStorage(user);
  }

  private checkPermissions(): void {
    this.getUserProfile().subscribe({
      next: (res) => {
        this.sharedDataService.setUserValue(res);
      },
      error: (err) => {
        console.error('Failed to fetch permissions:', err);
      },
    });
  }

  private getUserProfile(): Observable<AuthUserModel> {
    const fromObject: { [index: string]: string } = { reset: 'true' };
    const params = new HttpParams({ fromObject });
    return this.httpClient.get<AuthUserModel>(
      `${environment.tppCsToolAPI.url}/v1/authorization/user_profile`,
      { params }
    );
  }

  private checkUser(user: UserModel): void {
    this.userService.getUserByUserId(user.id).subscribe({
      next: (res) => this.sharedDataService.setUser(res),
      error: (err) => {
        if (err.status === 404) {
          this.createUser(user);
        } else {
          console.error('Failed to fetch user:', err);
        }
      },
    });
  }

  private createUser(user: UserModel): void {
    this.userService.createUser(user).subscribe({
      next: (res) => this.sharedDataService.setUser(res),
      error: (err) => {
        console.error('Failed to create user:', err);
        this.helperService.showErrorToastr(
          'Failed to create user. Please report the issue.'
        );
      },
    });
  }

  private setInfoToLocalStorage(user: User): void {
    if (!user.email) {
      throw new Error(`User is missing an email: ${JSON.stringify(user)}`);
    }
    localStorage.setItem('hub_email', user.email);
    const name = user.email
      .split('@')[0]
      .replace('.', ' ')
      .replace(/\b\w/g, (c) => c.toUpperCase());
    localStorage.setItem('hub_username', name);
  }

  clearAuthState(): void {
    localStorage.removeItem('hub_email');
    localStorage.removeItem('hub_username');
    this._isLoggedIn$.next(false);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  redirectAfterLogin(): void {
    const redirectLink = localStorage.getItem('redirect_url');
    if (redirectLink === '/login') {
      this.router.navigateByUrl('/phone');
    } else if (redirectLink) {
      window.location.href = redirectLink;
    }
  }
}
