import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-double-scenario',
  templateUrl: './double-scenario.component.html',
  styleUrls: ['./double-scenario.component.scss'],
})
export class DoubleScenarioComponent implements OnInit {
  @Output() wizardCompleted = new EventEmitter<any>();

  steps = ['Webhook Settings', 'Address Details', 'Confirmation'];
  activeStep = 0;
  form: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      webhookUrl: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
    });
  }

  previousStep(): void {
    if (this.activeStep > 0) {
      this.activeStep--;
    }
  }

  nextStep(): void {
    if (this.activeStep < this.steps.length - 1) {
      this.activeStep++;
    }
  }

  submit(): void {
    if (this.form.valid) {
      this.wizardCompleted.emit(this.form.value);
    } else {
      alert('Please complete all fields.');
    }
  }
}
